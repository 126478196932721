@use "sass:math";

@import "../../settings";
@import "../../lib/theme";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Button {
  @include animate;
  @include body;

  @include button-theme(
    "primary",
    $background-color: $color-primary,
    $text-color: $color-background,
    $gradient: false
  );

  @include button-theme(
    "primary-contrast",
    $background-color: $color-background,
    $text-color: $color-primary,
    $gradient: false
  );

  @include button-theme(
    "secondary",
    $background-color: $color-secondary,
    $text-color: $color-secondary-contrast,
    $gradient: false
  );

  @include button-theme(
    "tertiary",
    $background-color: $color-tertiary,
    $background-color-hover: $color-primary,
    $text-color: $color-tertiary-contrast,
    $text-color-hover: $color-background,
    $gradient: false
  );

  @include button-theme(
    "dimmed",
    $background-color: $color-gray,
    $text-color: $typography-color-text,
    $gradient: false
  );

  @include button-theme(
    "open",
    $background-color: transparent,
    $text-color: $color-primary,
    $border-color: $color-primary,
    $border-size: 1px,
    $gradient: false,
    $shadow: false
  );

  @include button-theme(
    "open-secondary",
    $background-color: transparent,
    $text-color: $color-gray-darker,
    $border-color: $color-gray-darker,
    $border-size: 1px,
    $gradient: false,
    $shadow: false
  );

  @include button-theme(
    "open-tertiary",
    $background-color: transparent,
    $text-color: $color-gray-darker,
    $border-color: $color-gray-darker,
    $border-size: 1px,
    $gradient: false,
    $shadow: false
  );

  @include button-theme("styled", $border-size: 1px, $gradient: false);

  @include button-theme(
    "transparent",
    $background-color: transparent,
    $text-color: $typography-color-text,
    $border-color: transparent,
    $shadow: false
  );
  align-items: center;
  appearance: none;
  border-radius: $grid-margin-1;
  box-sizing: border-box;
  display: inline-flex;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 1px;
  line-height: $grid-margin-5;
  outline-offset: -2px;
  padding: 0;
  position: relative;
  text-decoration: none;
  touch-action: none;
  user-select: none;

  &[disabled] {
    opacity: 0.5;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--pad {
    @include responsive(
      padding,
      $grid-margin-4 $grid-margin-4,
      $grid-container-margin $grid-margin-4
    );
  }

  &--normal#{&}--pad {
    @include responsive(
      padding,
      $grid-margin-4 $grid-margin-4,
      $grid-margin-3 $grid-margin-3
    );
  }

  &--min-width {
    min-width: math.div(1, 16) * 200rem;
  }

  &--vertical {
    flex-direction: column;
  }

  &--horizontal *:first-child {
    @include margin("auto", "margin-right");
  }

  &--vertical *:first-child {
    @include margin("auto", "margin-bottom");
  }
}
