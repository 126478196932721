@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/typography";

.Segment {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  &--small {
    @include scale(
      0.98
    ); // https://taiga.maykinmedia.nl/project/kieskompas/issue/1088
  }

  @include tablet {
    justify-content: space-between;
  }

  .Button {
    @include mobile-only {
      @include body--small;
      @include rtl-property(margin-right, $typography-margin-base * 0.5);
      min-height: $typography-font-size-text-small *
        $typography-line-height-text-small * 3 + 1 * $grid-container-margin;
      padding-left: $grid-margin-0;
      padding-right: $grid-margin-0;

      &:nth-child(1n + 4) {
        @include gutter(margin-top, 0.25);
      }

      &:nth-child(3n) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media (max-height: math.div(1, 16)*567rem) {
      font-size: math.div(1, 16) * 10rem;
      // height: 1/16*10rem * $typography-line-height-text-small * 3;
      padding-bottom: 0;
      padding-top: 0;
    }

    @include tablet {
      @include body--big;
      @include rtl-property(margin-right, $typography-margin-base);
      // height: $typography-font-size-text-big * $typography-line-height-text-big * 3 + 2 * $grid-container-margin;

      &:last-child {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include laptop {
      @include body--big;
    }
  }

  @for $i from 1 through 6 {
    &--justify-#{$i} .Button {
      @include mobile-only {
        $c: ceil($i * 0.5);
        width: calc(
          #{math.div(100, $c) * 1%} - #{math.div(
              1 * $typography-margin-base,
              $c
            )}
        );
      }

      @include tablet {
        width: calc(
          #{math.div(100, $i) * 1%} - #{math.div(
              5 * $typography-margin-base,
              $i
            )}
        );
      }
    }
  }

  &--small .Button {
    font-size: $typography-font-size-text;

    @include tablet {
      min-height: math.div(1, 16) * 70rem;
    }
  }

  &--smaller .Button {
    font-size: $typography-font-size-text;

    @include tablet {
      height: math.div(1, 16) * 56rem;
    }
  }

  & + & {
    @include gutter(margin-top);
  }
}
