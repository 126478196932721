@import "../../settings";
@import "~microscope-sass/lib/typography";

.Lead {
  @include h4;

  &--center {
    text-align: center;
  }
}
