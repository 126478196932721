@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.JustificationTable {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;

  //
  // Table layout
  //

  &__table {
    border-collapse: collapse;
    min-width: 100%;
    table-layout: fixed;

    @include mobile-only {
      display: flex;
    }

    @include tablet {
      height: 100%;
    }
  }

  &__row {
    @include mobile-only {
      background-color: $color-black;
      display: flex;
      flex-direction: column;
    }
  }

  &__head &__row,
  &__head &__cell {
    @include mobile-only() {
      height: 100% !important;
      width: 100px;
    }
  }

  &__body {
    @include mobile-only {
      width: 100%;
    }
  }

  &__cell {
    @include color-background;
    color: $typography-color-h;
    min-width: math.div(100, 16) * 1rem;
    outline: math.div(1, 16) * 1rem solid $color-gray;
    padding: 0;
    position: relative;
    width: math.div(100, 7) * 1%;

    @include mobile-only {
      height: 100%;
      width: 100%;
    }
  }

  // Exception for justifications on mobile with vertical space left.
  &__body &__row:last-child &__cell {
    background-color: $color-black;

    @include mobile-only {
      @include rows(2, padding-bottom);
      outline: none;
    }
  }

  &__head &__cell {
    @include shadow($offset-x: $transform-shadow-default-blur);
    @include responsive(height, auto, math.div(120, 16) * 1rem);
    padding-bottom: 0;
    padding-top: 0;
    top: 0;
    z-index: 20;

    @include mobile-only {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      max-height: 7.5rem;
      padding: $grid-container-margin;
    }

    @include tablet {
      position: sticky;
    }

    @include rtl {
      @include shadow($offset-x: -1 * $transform-shadow-default-blur);
    }
  }

  &__head &__cell .Text {
    @include mobile-only {
      @include body--small;
    }
  }

  &__body &__cell {
    z-index: 0;
  }

  //
  // Comparative entities
  //

  &__comparative-entities {
    @include rows(3, min-height);
    min-width: 2 * $grid-row-height + $grid-margin-2;
    padding: $grid-container-margin;

    @include mobile-only {
      @include rows(3, max-height);
      box-sizing: border-box;
    }
  }

  &__comparative-entities .CompassParty {
    position: relative;
    z-index: 10;
  }

  //
  // Justification
  //

  &__justification {
    @include container-margin(
      $properties: (
        padding-left,
        padding-right,
      )
    );
    box-sizing: border-box;
    height: 100%;
    left: 0;
    max-width: 100vw;
    padding: $grid-container-margin $grid-container-margin $grid-row-height +
      $grid-container-margin;
    position: sticky;
    top: 0;
    width: 100%;

    @include tablet {
      max-width: calc(100vw - 1 / 16 * 200rem);
    }
  }

  &__justification .Button,
  &__justification .SubCaption,
  &__justification .Text {
    @include margin(auto);
    @include color-background(color);
  }

  &__justification .SubCaption,
  &__justification .Text,
  &__justification .Chip {
    margin: auto;
    max-width: $grid-container-size-big;
  }

  &__justification .SubCaption {
    padding-top: $grid-margin-10 + $grid-margin-5 - $grid-container-margin;
  }

  &__justification .Button {
    @include rtl-property(right, $grid-margin-1);
    position: absolute;
    top: $grid-margin-1;
  }

  &__body &__cell {
    //@include responsive(height, auto, 50%);
    @include responsive(vertical-align, top, middle);
    box-sizing: border-box;
  }
}
