@import "../../settings";
@import "~microscope-sass/lib/responsive";

.FormActions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: $grid-margin-10 0;

  .Button {
    @include mobile-only {
      width: 100%;
    }
  }
}
