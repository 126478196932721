@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  /// Set layout to match viewport height on mobile (iOS) devices in portrait orientation.
  @media (pointer: coarse) {
    @include mobile-only {
      @media (orientation: landscape) {
        height: auto !important;
      }

      @media (orientation: portrait) {
        height: 100vh !important;
      }
    }
  }

  /// Prevent layout from limiting access to zoomed browsers.
  @media (pointer: fine) and (max-height: 567px) {
    height: auto !important;
  }

  &--debug {
    background-color: $color-secondary;
  }

  &--debug .Col {
    @include outline($color-gray-darker, 1px, dashed);
    @include rows(1, min-height);

    @for $i from 1 through 12 {
      &:nth-child(#{$i}):before {
        @include body;
        @include body--small;
        @include center-hv();
        color: $color-gray-darker;
        content: "#{$i}";
      }
    }
  }

  &--fill {
    @include color-background;
  }

  &--scroll {
    overflow-y: auto;
  }
}
