@use "sass:math";

$breakpoint-mobile-s: 320px;
$breakpoint-mobile-m: 375px;
$breakpoint-mobile-l: 425px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;
$breakpoint-desktop: 1200px;
$responsive-default-media: all !default;

$animation-default-duration-fast: 0.05s;
$animation-default-duration-medium: 0.1s;
$animation-default-duration-slow: 0.5s;
$animation-default-delay: 0s !default;
$animation-default-function: ease-in;

$color-black: #3a3f43;
$color-white: #fff !default;
$color-gray: #f0f0f0;
$color-gray-lighter: #fafafa;
$color-gray-dark: #c1c1c1;
$color-gray-darker: #777;
$color-blue: #27a6ea;
$color-green: #47d09b;
$color-red: #f00 !default;
$color-orange: #ffa500 !default;
$color-yellow: #ff0 !default;
$color-primary: var(--color-primary, $color-blue);
$color-background: var(
  --color-primary-contrast,
  $color-white
); /// Acts as primary contrast (e.g. text).
$color-secondary: var(--color-secondary, $color-gray);
$color-secondary-contrast: var(
  --color-secondary-contrast,
  $color-black
); /// Note: not part of microscope-sass.
$color-tertiary: var(
  --color-tertiary,
  #303030
); /// Note: not part of microscope-sass.
$color-tertiary-contrast: var(
  --color-tertiary-contrast,
  $color-white
); /// Note: not part of microscope-sass.
$color-border: var(--color-border, scale_color($color-blue, $lightness: -20%));
$color-border-secondary: var(
  --color-border-secondary,
  scale_color($color-gray, $lightness: -20%)
); /// Note: not part of microscope-sass.
$color-danger: $color-red !default;
$color-info: #0071ad;
$color-success: $color-green !default;
$color-warning: $color-orange !default;

$grid-margin-0: math.div(1, 16) * 2rem; // 2px
$grid-margin-1: math.div(1, 16) * 4rem; // 4px
$grid-margin-2: math.div(1, 16) * 8rem; // 8px
$grid-margin-3: math.div(1, 16) * 12rem; // 12px
$grid-margin-4: math.div(1, 16) * 16rem; // 16px
$grid-margin-5: math.div(1, 16) * 20rem; // 20px
$grid-margin-6: math.div(1, 16) * 24rem; // 24px
$grid-margin-7: math.div(1, 16) * 28rem; // 28px
$grid-margin-8: math.div(1, 16) * 32rem; // 32px
$grid-margin-9: math.div(1, 16) * 36rem; // 36px
$grid-margin-10: math.div(1, 16) * 40rem; // 40px

$grid-columns-mobile: 4 !default;
$grid-columns-tablet: 9 !default;
$grid-columns-laptop: 12 !default;
$grid-columns-desktop: 12 !default;
$grid-gutter-base: 32px;
$grid-gutter-mobile: $grid-gutter-base !default;
$grid-gutter-tablet: $grid-gutter-base !default;
$grid-gutter-laptop: $grid-gutter-base !default;
$grid-gutter-desktop: $grid-gutter-base !default;
$grid-row-height: $grid-margin-10;
$grid-container-padding-base: 0;
$grid-container-margin-base: $grid-margin-5;
$grid-container-margin: $grid-container-margin-base;
$grid-container-margin-small: $grid-container-margin-base;
$grid-container-margin-big: $grid-container-margin-base;
$grid-container-padding: $grid-container-padding-base !default;
$grid-container-padding-small: $grid-container-padding-base !default;
$grid-container-padding-big: $grid-container-padding-base;
$grid-container-size-small: math.div(1, 16) * 560rem;
$grid-container-size: math.div(1, 16) * 580rem;
$grid-container-size-big: math.div(1, 16) * 800rem;

$typography-color-base: $color-black !default;
$typography-color-text: $typography-color-base;
$typography-color-link: inherit;
$typography-color-link-hover: $typography-color-link !default;
$typography-color-h: $typography-color-base !default;
$typography-default-hover-link: false;
$typography-decoration-link: underline !default;
$typography-font-family-base: proxima-nova, sans-serif;
$typography-font-family-text: $typography-font-family-base !default;
$typography-font-family-h: Lora, serif;
$typography-font-size-text: math.div(1, 16) * 14rem;
$typography-font-size-text-mobile: $typography-font-size-text !default;
$typography-font-size-text-tablet: $typography-font-size-text !default;
$typography-font-size-text-laptop: $typography-font-size-text !default;
$typography-font-size-text-desktop: $typography-font-size-text !default;
$typography-font-size-text-big: math.div(1, 16) * 16rem;
$typography-font-size-text-big-mobile: $typography-font-size-text-big !default;
$typography-font-size-text-big-tablet: $typography-font-size-text-big !default;
$typography-font-size-text-big-laptop: $typography-font-size-text-big !default;
$typography-font-size-text-big-desktop: $typography-font-size-text-big !default;
$typography-font-size-text-small: math.div(1, 16) * 12rem;
$typography-font-size-text-small-mobile: $typography-font-size-text-small !default;
$typography-font-size-text-small-tablet: $typography-font-size-text-small !default;
$typography-font-size-text-small-laptop: $typography-font-size-text-small !default;
$typography-font-size-text-small-desktop: $typography-font-size-text-small !default;
$typography-font-size-h1: math.div(1, 16) * 32rem;
$typography-font-size-h1-mobile: $typography-font-size-h1 !default;
$typography-font-size-h1-tablet: $typography-font-size-h1 !default;
$typography-font-size-h1-laptop: $typography-font-size-h1 !default;
$typography-font-size-h1-desktop: $typography-font-size-h1 !default;
$typography-font-size-h2: math.div(1, 16) * 26rem;
$typography-font-size-h2-mobile: $typography-font-size-h2 !default;
$typography-font-size-h2-tablet: $typography-font-size-h2 !default;
$typography-font-size-h2-laptop: $typography-font-size-h2 !default;
$typography-font-size-h2-desktop: $typography-font-size-h2 !default;
$typography-font-size-h3: math.div(1, 16) * 22rem;
$typography-font-size-h3-mobile: $typography-font-size-h3 !default;
$typography-font-size-h3-tablet: $typography-font-size-h3 !default;
$typography-font-size-h3-laptop: $typography-font-size-h3 !default;
$typography-font-size-h3-desktop: $typography-font-size-h3 !default;
$typography-font-size-h4: math.div(1, 16) * 20rem;
$typography-font-size-h4-mobile: $typography-font-size-h4 !default;
$typography-font-size-h4-tablet: $typography-font-size-h4 !default;
$typography-font-size-h4-laptop: $typography-font-size-h4 !default;
$typography-font-size-h4-desktop: $typography-font-size-h4 !default;
$typography-font-size-h5: $typography-font-size-text-big;
$typography-font-size-h5-mobile: $typography-font-size-h5 !default;
$typography-font-size-h5-tablet: $typography-font-size-h5 !default;
$typography-font-size-h5-laptop: $typography-font-size-h5 !default;
$typography-font-size-h5-desktop: $typography-font-size-h5 !default;
$typography-font-size-h6: $typography-font-size-text !default;
$typography-font-size-h6-mobile: $typography-font-size-h6 !default;
$typography-font-size-h6-tablet: $typography-font-size-h6 !default;
$typography-font-size-h6-laptop: $typography-font-size-h6 !default;
$typography-font-size-h6-desktop: $typography-font-size-h6 !default;
$typography-font-weight-text: 400;
$typography-font-weight-h: 400;
$typography-font-weight-h1: $typography-font-weight-h !default;
$typography-font-weight-h2: $typography-font-weight-h !default;
$typography-font-weight-h3: $typography-font-weight-h !default;
$typography-font-weight-h4: $typography-font-weight-text;
$typography-font-weight-h5: $typography-font-weight-h4;
$typography-font-weight-h6: bold;
$typography-line-height-base: 1.5;
$typography-line-height-text: $typography-line-height-base !default;
$typography-line-height-text-mobile: $typography-line-height-text !default;
$typography-line-height-text-tablet: $typography-line-height-text !default;
$typography-line-height-text-laptop: $typography-line-height-text !default;
$typography-line-height-text-desktop: $typography-line-height-text !default;
$typography-line-height-text-big: $typography-line-height-base !default;
$typography-line-height-text-big-mobile: $typography-line-height-text-big !default;
$typography-line-height-text-big-tablet: $typography-line-height-text-big !default;
$typography-line-height-text-big-laptop: $typography-line-height-text-big !default;
$typography-line-height-text-big-desktop: $typography-line-height-text-big !default;
$typography-line-height-text-small: $typography-line-height-base;
$typography-line-height-text-small-mobile: $typography-line-height-text-small !default;
$typography-line-height-text-small-tablet: $typography-line-height-text-small !default;
$typography-line-height-text-small-laptop: $typography-line-height-text-small !default;
$typography-line-height-text-small-desktop: $typography-line-height-text-small !default;
$typography-line-height-h: 1.5;
$typography-line-height-h1: $typography-line-height-h !default;
$typography-line-height-h1-mobile: $typography-line-height-h1 !default;
$typography-line-height-h1-tablet: $typography-line-height-h1 !default;
$typography-line-height-h1-laptop: $typography-line-height-h1 !default;
$typography-line-height-h1-desktop: $typography-line-height-h1 !default;
$typography-line-height-h2: $typography-line-height-h !default;
$typography-line-height-h2-mobile: $typography-line-height-h2 !default;
$typography-line-height-h2-tablet: $typography-line-height-h2 !default;
$typography-line-height-h2-laptop: $typography-line-height-h2 !default;
$typography-line-height-h2-desktop: $typography-line-height-h2 !default;
$typography-line-height-h3: $typography-line-height-h !default;
$typography-line-height-h3-mobile: $typography-line-height-h3 !default;
$typography-line-height-h3-tablet: $typography-line-height-h3 !default;
$typography-line-height-h3-laptop: $typography-line-height-h3 !default;
$typography-line-height-h3-desktop: $typography-line-height-h3 !default;
$typography-line-height-h4: $typography-line-height-h !default;
$typography-line-height-h4-mobile: $typography-line-height-h4 !default;
$typography-line-height-h4-tablet: $typography-line-height-h4 !default;
$typography-line-height-h4-laptop: $typography-line-height-h4 !default;
$typography-line-height-h4-desktop: $typography-line-height-h4 !default;
$typography-line-height-h5: $typography-line-height-h !default;
$typography-line-height-h5-mobile: $typography-line-height-h5 !default;
$typography-line-height-h5-tablet: $typography-line-height-h5 !default;
$typography-line-height-h5-laptop: $typography-line-height-h5 !default;
$typography-line-height-h5-desktop: $typography-line-height-h5 !default;
$typography-line-height-h6: $typography-line-height-h !default;
$typography-line-height-h6-mobile: $typography-line-height-h6 !default;
$typography-line-height-h6-tablet: $typography-line-height-h6 !default;
$typography-line-height-h6-laptop: $typography-line-height-h6 !default;
$typography-line-height-h6-desktop: $typography-line-height-h6 !default;
$typography-letter-spacing-base: normal;
$typography-letter-spacing-text: $typography-letter-spacing-base !default;
$typography-letter-spacing-text-mobile: $typography-letter-spacing-text !default;
$typography-letter-spacing-text-tablet: $typography-letter-spacing-text !default;
$typography-letter-spacing-text-laptop: $typography-letter-spacing-text !default;
$typography-letter-spacing-text-desktop: $typography-letter-spacing-text !default;
$typography-letter-spacing-text-big: $typography-letter-spacing-base !default;
$typography-letter-spacing-text-big-mobile: $typography-letter-spacing-text-big !default;
$typography-letter-spacing-text-big-tablet: $typography-letter-spacing-text-big !default;
$typography-letter-spacing-text-big-laptop: $typography-letter-spacing-text-big !default;
$typography-letter-spacing-text-big-desktop: $typography-letter-spacing-text-big !default;
$typography-letter-spacing-text-small: $typography-letter-spacing-base !default;
$typography-letter-spacing-text-small-mobile: $typography-letter-spacing-text-small !default;
$typography-letter-spacing-text-small-tablet: $typography-letter-spacing-text-small !default;
$typography-letter-spacing-text-small-laptop: $typography-letter-spacing-text-small !default;
$typography-letter-spacing-text-small-desktop: $typography-letter-spacing-text-small !default;
$typography-letter-spacing-h: normal;
$typography-letter-spacing-h1: $typography-letter-spacing-h !default;
$typography-letter-spacing-h1-mobile: $typography-letter-spacing-h1 !default;
$typography-letter-spacing-h1-tablet: $typography-letter-spacing-h1 !default;
$typography-letter-spacing-h1-laptop: $typography-letter-spacing-h1 !default;
$typography-letter-spacing-h1-desktop: $typography-letter-spacing-h1 !default;
$typography-letter-spacing-h2: $typography-letter-spacing-h !default;
$typography-letter-spacing-h2-mobile: $typography-letter-spacing-h2 !default;
$typography-letter-spacing-h2-tablet: $typography-letter-spacing-h2 !default;
$typography-letter-spacing-h2-laptop: $typography-letter-spacing-h2 !default;
$typography-letter-spacing-h2-desktop: $typography-letter-spacing-h2 !default;
$typography-letter-spacing-h3: $typography-letter-spacing-h !default;
$typography-letter-spacing-h3-mobile: $typography-letter-spacing-h3 !default;
$typography-letter-spacing-h3-tablet: $typography-letter-spacing-h3 !default;
$typography-letter-spacing-h3-laptop: $typography-letter-spacing-h3 !default;
$typography-letter-spacing-h3-desktop: $typography-letter-spacing-h3 !default;
$typography-letter-spacing-h4: $typography-letter-spacing-h !default;
$typography-letter-spacing-h4-mobile: $typography-letter-spacing-h4 !default;
$typography-letter-spacing-h4-tablet: $typography-letter-spacing-h4 !default;
$typography-letter-spacing-h4-laptop: $typography-letter-spacing-h4 !default;
$typography-letter-spacing-h4-desktop: $typography-letter-spacing-h4 !default;
$typography-letter-spacing-h5: $typography-letter-spacing-h !default;
$typography-letter-spacing-h5-mobile: $typography-letter-spacing-h5 !default;
$typography-letter-spacing-h5-tablet: $typography-letter-spacing-h5 !default;
$typography-letter-spacing-h5-laptop: $typography-letter-spacing-h5 !default;
$typography-letter-spacing-h5-desktop: $typography-letter-spacing-h5 !default;
$typography-letter-spacing-h6: $typography-letter-spacing-h !default;
$typography-letter-spacing-h6-mobile: $typography-letter-spacing-h6 !default;
$typography-letter-spacing-h6-tablet: $typography-letter-spacing-h6 !default;
$typography-letter-spacing-h6-laptop: $typography-letter-spacing-h6 !default;
$typography-letter-spacing-h6-desktop: $typography-letter-spacing-h6 !default;
$typography-margin-base: $typography-font-size-text;
$typography-margin-text: $typography-margin-base !default;
$typography-margin-text-mobile: $typography-margin-text !default;
$typography-margin-text-tablet: $typography-margin-text !default;
$typography-margin-text-laptop: $typography-margin-text !default;
$typography-margin-text-desktop: $typography-margin-text !default;
$typography-margin-hr: $typography-margin-base !default;
$typography-margin-hr-mobile: $typography-margin-hr !default;
$typography-margin-hr-tablet: $typography-margin-hr !default;
$typography-margin-hr-laptop: $typography-margin-hr !default;
$typography-margin-hr-desktop: $typography-margin-hr !default;
$typography-margin-list: $typography-margin-base !default;
$typography-margin-list-mobile: $typography-margin-list !default;
$typography-margin-list-tablet: $typography-margin-list !default;
$typography-margin-list-laptop: $typography-margin-list !default;
$typography-margin-list-desktop: $typography-margin-list !default;
$typography-margin-table: $typography-margin-base 0 !default;
$typography-margin-table-mobile: $typography-margin-table !default;
$typography-margin-table-tablet: $typography-margin-table !default;
$typography-margin-table-laptop: $typography-margin-table !default;
$typography-margin-table-desktop: $typography-margin-table !default;
$typography-margin-h: math.div(1, 16) * 14rem;
$typography-margin-h1: $typography-margin-h !default;
$typography-margin-h1-mobile: $typography-margin-h1 !default;
$typography-margin-h1-tablet: $typography-margin-h1 !default;
$typography-margin-h1-laptop: $typography-margin-h1 !default;
$typography-margin-h1-desktop: $typography-margin-h1 !default;
$typography-margin-h2: $typography-margin-h !default;
$typography-margin-h2-mobile: $typography-margin-h2 !default;
$typography-margin-h2-tablet: $typography-margin-h2 !default;
$typography-margin-h2-laptop: $typography-margin-h2 !default;
$typography-margin-h2-desktop: $typography-margin-h2 !default;
$typography-margin-h3: $typography-margin-h !default;
$typography-margin-h3-mobile: $typography-margin-h3 !default;
$typography-margin-h3-tablet: $typography-margin-h3 !default;
$typography-margin-h3-laptop: $typography-margin-h3 !default;
$typography-margin-h3-desktop: $typography-margin-h3 !default;
$typography-margin-h4: $typography-margin-h !default;
$typography-margin-h4-mobile: $typography-margin-h4 !default;
$typography-margin-h4-tablet: $typography-margin-h4 !default;
$typography-margin-h4-laptop: $typography-margin-h4 !default;
$typography-margin-h4-desktop: $typography-margin-h4 !default;
$typography-margin-h5: $typography-margin-h !default;
$typography-margin-h5-mobile: $typography-margin-h5 !default;
$typography-margin-h5-tablet: $typography-margin-h5 !default;
$typography-margin-h5-laptop: $typography-margin-h5 !default;
$typography-margin-h5-desktop: $typography-margin-h5 !default;
$typography-margin-h6: $typography-margin-h !default;
$typography-margin-h6-mobile: $typography-margin-h6 !default;
$typography-margin-h6-tablet: $typography-margin-h6 !default;
$typography-margin-h6-laptop: $typography-margin-h6 !default;
$typography-margin-h6-desktop: $typography-margin-h6 !default;
$typography-margin-property-base: margin-bottom !default;
$typography-margin-property-text: $typography-margin-property-base !default;
$typography-margin-property-h: $typography-margin-property-base !default;
$typography-margin-property-hr: $typography-margin-property-base !default;
$typography-margin-property-list: $typography-margin-property-base !default;
$typography-margin-property-table: margin !default;
$typography-margin-property-h1: $typography-margin-property-h !default;
$typography-margin-property-h2: $typography-margin-property-h !default;
$typography-margin-property-h3: $typography-margin-property-h !default;
$typography-margin-property-h4: $typography-margin-property-h !default;
$typography-margin-property-h5: $typography-margin-property-h !default;
$typography-margin-property-h6: $typography-margin-property-h !default;
$typography-padding-table: $grid-margin-1 0 !default;
$typography-size-border: $grid-margin-0;
$typography-style-border: solid;

$transform-shadow-default-color: rgba(0, 0, 0, 0.1);
$transform-shadow-default-offset-x: 0;
$transform-shadow-default-offset-y: $grid-margin-0;
$transform-shadow-default-blur: $grid-margin-2;
$transform-shadow-default-spread: 0;

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-Italic.woff2") format("woff2"),
    url("../fonts/Lora-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-BoldItalic.woff2") format("woff2"),
    url("../fonts/Lora-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Lora-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-MediumItalic.woff2") format("woff2"),
    url("../fonts/Lora-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-Medium.woff2") format("woff2"),
    url("../fonts/Lora-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-Regular.woff2") format("woff2"),
    url("../fonts/Lora-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-Bold.woff2") format("woff2"),
    url("../fonts/Lora-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-SemiBold.woff2") format("woff2"),
    url("../fonts/Lora-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
