@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/typography";

.Caption {
  @include h4;
  font-family: $typography-font-family-text;

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--black {
    color: $color-black;
  }

  &--primary {
    @include color-primary;
  }

  &--primary-contrast {
    @include color-background(color);
  }

  &--secondary {
    @include color-secondary;
  }

  &--tertiary {
    color: $color-tertiary;
  }
}
