@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "../../lib/number";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";

.Hero {
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  width: 100%;

  @include breakpoint($max: $breakpoint-laptop - 1px) {
    @include rows(2, padding-top);
  }

  @include laptop {
    align-items: center;
    flex-direction: row;
    height: 0;
    padding-top: math.div(9, 21) * 100%; // 21:9
    padding-left: 10%;
  }

  .LanguageSwitcher {
    @include center-h;
    top: math.div($grid-container-margin-base, 2);
    z-index: 10;

    @include tablet {
      top: $grid-container-margin-base;
    }
  }

  .Logo {
    @include rows(2);
    position: absolute;
    top: $grid-container-margin-base;
    z-index: 10;

    @include breakpoint($max: $breakpoint-laptop - 1px) {
      @include center-h;
    }

    @include laptop {
      @include rtl-property(left, $grid-container-margin-base);
    }
  }

  .LanguageSwitcher + .Logo {
    @include mobile-only {
      top: $grid-container-margin-base * 2;
    }
  }

  &__background {
    @include breakpoint($max: $breakpoint-laptop - 1px) {
      overflow: hidden;
      mask: linear-gradient(
        180deg,
        transparent,
        black 10%,
        black 90%,
        transparent
      );
    }

    @include laptop {
      @include overlay;
    }
  }

  &__background .Image,
  &__background .Image__image {
    @include breakpoint($max: $breakpoint-laptop - 1px) {
      width: 200%;
    }

    @include laptop {
      height: auto !important;
      width: 100% !important;
    }
  }

  &__background .Image__image {
    @include breakpoint($max: $breakpoint-laptop - 1px) {
      left: -50%;
      position: relative;
    }
  }

  &__body {
    @include container(flex);
    @include gutter(padding-left, 1);
    @include gutter(padding-right, 1);
    max-width: math.div(1, 16) * 1440rem !important;
    position: relative;

    @include laptop {
      @include center-hv;
    }
  }

  &__body > .Body {
    @include columns-responsive(
      $grid-columns-mobile,
      $grid-columns-tablet,
      math.div($grid-columns-laptop, $grid-columns-desktop) * 4,
      4
    );
    @include rtl-property(margin-left, 0);
  }
}
