@import "../../settings/index";
@import "../../lib/i18n";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";

.Universe {
  //@include overlay;
  //box-sizing: border-box;
  color: $color-primary;
  width: 100%;
  height: 100%;
  text-align: center;
  //padding-top: 50%;

  @media (orientation: portrait) {
    @include rtl(text-align, left, right);
  }

  &__svg {
    @include color-info;
    overflow: visible;
    width: 80%;

    @media (orientation: portrait) {
      @include center-v;
      @include translate($y: -100%);
      width: auto;
      height: 40%;
    }
  }

  &__wrapper {
    @media (orientation: portrait) {
      @include rotate(270, center bottom);
    }
  }

  &__title {
    @media (orientation: portrait) {
      display: none;
    }
  }

  &[style] &__svg {
    color: inherit;
  }

  @media dev {
  }
}
