@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/typography";

.Toolbar {
  @include responsive(flex-direction, column, row);
  display: flex;
  justify-content: center;
  padding: $grid-container-margin-base 0;

  &--end {
    justify-content: flex-end;
  }

  &--side {
    justify-content: flex-start;
  }

  &--stretch {
    width: 100%;
  }

  > * {
    @include mobile-only {
      @include margin;
      width: 100%;
    }

    @include tablet {
      margin: 0 $grid-container-margin-base;

      &:first-child {
        @include rtl-property(margin-left, 0);
      }

      &:last-child {
        @include rtl-property(margin-right, 0);
      }
    }
  }
}
