@import "../../settings";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/util";
@import "~microscope-sass/lib/transform";

@keyframes zoom {
  0% {
    @include scale(0);
  }

  100% {
    @include scale(1);
  }
}

.CompassParty {
  @at-root {
    button#{&} {
      appearance: none;
      background-color: transparent;
      border: none;
      border-radius: 100%;
      outline: none;
      padding: 0;
    }
  }

  /// Focus is handled by a custom outlne.
  &:focus {
    outline: none;
  }

  &:focus &__outline,
  &:hover &__outline,
  &--active &__outline {
    visibility: visible !important;
  }

  &__icon {
    border-radius: 100%;
    cursor: pointer;
    overflow: visible;
  }

  &__icon--primary {
    color: $color-primary;
  }

  &__background {
    position: relative;
    z-index: -1;
  }

  &__fill {
    fill: $color-primary;
  }

  &__shadow {
    @include shadow;
  }

  &__shadow,
  image {
    @include scale(1);
    transform-origin: center;
  }

  &--animate &__shadow,
  &--animate image {
    animation-duration: $animation-default-duration-slow;
    animation-iteration-count: 1;
    animation-name: zoom;
  }

  &:focus image,
  &:hover image {
    @include scale(1.2);
  }
}
