@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Popover {
  @include animate;
  @include absolute(calc(50% + #{0.5 * $grid-row-height}));
  @include color-primary(background-color);
  @include color-background(color);
  @include rtl-property(left, calc(50% - #{1.25 * $grid-row-height}));
  @include shadow;
  @include translate($y: -$grid-margin-4);
  @include responsive(border-radius, 0, $grid-margin-1);
  background-image: linear-gradient(
    to bottom,
    transparent,
    rgba($color-black, 0.2)
  );
  box-sizing: border-box;
  max-width: 440px;
  opacity: 0;
  transform-style: preserve-3d;
  visibility: hidden;
  width: 0;
  z-index: 1000;

  @include mobile-only {
    @include rtl-property(left, 0 !important);
    height: calc(100%);
    max-width: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;
  }

  &--active {
    @include translate($y: 0);
    opacity: 1;
    visibility: visible;
    width: 100vw;
  }

  &::before {
    @include absolute(-0.25 * $grid-row-height);
    @include hide-on-mobile;
    @include rotate(45);
    @include rows(0.5, height);
    @include rows(0.5, width);
    background-color: inherit;
    content: "";
    left: $grid-row-height;
    z-index: -10;
  }

  [dir="rtl"] &::before {
    left: auto;
    right: $grid-row-height;
  }

  &__header,
  &__body {
    @include body--big; // Required for icons.
    box-sizing: border-box;
    display: flex;
    padding: $grid-margin-5 $grid-margin-10;
    width: 100%;
  }

  &__header {
    justify-content: space-between;
  }

  &__header .IconButton .Button {
    @include color-background(color);
  }

  &__body {
    flex-wrap: wrap;
    justify-content: flex-end;

    @include mobile-only {
      padding-bottom: $grid-margin-10 * 1.5;
      padding-top: $grid-margin-10 * 1.5;
    }
  }

  &__header {
    @include border($color: currentColor);
  }

  .Body {
    width: 100%;
  }

  .Title,
  .SubTitle,
  .SubSubTitle,
  .Caption,
  .SubCaption,
  .SubSubCaption,
  .Text {
    @include color-background(color);
  }

  &__body .Title,
  &__body .SubTitle,
  &__body .SubSubTitle,
  &__body .Caption,
  &__body .SubCaption,
  &__body .SubSubCaption,
  &__body .Text {
    @include margin;
  }

  &__body .Icon + .Title,
  &__body .Icon + .SubTitle,
  &__body .Icon + .SubSubTitle,
  &__body .Icon + .Caption,
  &__body .Icon + .SubCaption,
  &__body .Icon + .SubSubCaption,
  &__body .Icon + .Text {
    @include rtl-property(margin-left, $grid-container-margin);
    width: calc(100% - #{$grid-margin-10 + $grid-container-margin});
  }

  .Text {
    align-items: center;
    display: flex;
    min-height: $grid-margin-10;
    width: 100%;
  }

  .Icon {
    @include margin("auto", "margin-bottom");
  }

  &__body .Button {
    @include rows(1, margin-top);
    margin-left: $grid-row-height + $grid-container-margin;
    width: auto;
  }
}
