@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/typography";

.FormControl {
  @include responsive(flex-direction, column, row);
  display: flex;

  @include tablet {
    align-items: center;
    justify-content: space-between;
  }

  > .Label {
    @include rows(1, min-height);
    @include rtl-property(padding-right, $typography-margin-base);
    align-items: center;
    display: flex;
  }

  > * {
    @include mobile-only {
      width: 100%;
    }
  }

  > *:first-child:last-child {
    width: 100%;
  }

  .Input,
  .Select {
    @include tablet {
      flex-shrink: 0;
    }
  }
}
