@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.ThemeFilter {
  @include responsive(height, auto, 100%);
  @include responsive(position, fixed, sticky);
  @include responsive(flex-direction, row, column);
  bottom: 0;

  display: flex;
  flex-shrink: 1;
  left: 0;
  overflow: hidden;
  width: 100%;
  z-index: 10;

  @include tablet {
    &__body .Panel {
      mask: linear-gradient(
          to bottom,
          $color-background calc(100% - #{2 * $grid-row-height}),
          transparent
        )
        0 0/100% 100%;
    }

    &--scrolled-end &__body .Panel {
      mask: none;
    }
  }

  &__head {
    @include shadow;
    position: relative;

    @include mobile-only {
      width: 100%;
    }
  }

  &--scrolled-start &__head {
    box-shadow: none;
  }

  &__head .Select {
    @include show-on-mobile;

    @include tablet {
      visibility: hidden;
    }
  }

  &__body {
    @include hide-on-mobile;
    @include shadow;

    @include mobile-only {
      visibility: hidden;
    }
  }

  &__head .Panel {
    @include container-margin(
      $properties: (
        padding,
      )
    );

    @include tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .Panel__body {
    @include responsive(flex-direction, row, column);

    @include mobile-only {
      width: 100%;
    }
  }

  &__head .Panel__body {
    min-height: 0;

    @include mobile-only {
      @include container-margin(
        $properties: (
          gap,
        )
      );
    }
  }

  &__head .SubCaption {
    @include ellipsis;
    margin-bottom: 0 !important;

    @include mobile-only {
      flex-basis: 50%;
    }
  }

  &__head .Select {
    flex-basis: 50%;
  }

  &__body {
    height: 100%;
    overflow: auto;
  }

  &__body .Panel__body::after {
    @include container-margin($properties: padding-bottom);
    content: "";
    padding-right: 1px;
    width: 0;
  }

  .Panel {
    overflow-y: auto;
  }

  .Panel .Panel__body .Toggle {
    @include mobile-only {
      @include margin("auto", "margin-right");
      margin-bottom: 0;
      white-space: nowrap;

      &:first-child {
        @include margin(true, "margin-left");
      }

      &:last-child {
        @include margin(true, "margin-right");
      }
    }
  }
}
