@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";
@import "../../lib/i18n";

.ComparativeEntityFilter {
  @include responsive(height, auto, 100%);
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  left: 0;
  overflow: hidden;
  width: 100%;

  ///
  /// Mask.
  ///

  &:not(#{&}--scrolled-start) {
    @include mobile-only {
      @include rtl(
        mask,
        linear-gradient(
            to left,
            $color-background calc(100% - #{2 * $grid-row-height}),
            transparent
          )
          0 0/100% 100%,
        linear-gradient(
            to right,
            $color-background calc(100% - #{2 * $grid-row-height}),
            transparent
          )
          0 0/100% 100%
      );
    }
  }

  &__body .Panel {
    @include mobile-only {
      @include rtl(
        mask,
        linear-gradient(
            to right,
            $color-background calc(100% - #{2 * $grid-row-height}),
            transparent
          )
          0 0/100% 100%,
        linear-gradient(
            to left,
            $color-background calc(100% - #{2 * $grid-row-height}),
            transparent
          )
          0 0/100% 100%
      );
    }
    @include tablet {
      mask: linear-gradient(
          to bottom,
          $color-background calc(100% - #{4 * $grid-row-height}),
          transparent
        )
        0 0/100% 100%;
    }
  }

  &--scrolled-end &__body .Panel {
    mask: none !important;
  }

  ///
  /// Head.
  ///

  &__head {
    @include shadow;
    position: relative;
    z-index: 10;
  }

  &--scrolled-start &__head {
    box-shadow: none;
  }

  &__head .Panel {
    @include hide-on-mobile;
  }

  &__head .Panel__body {
    min-height: 0;
  }

  &__head .SubCaption {
    margin-bottom: 0;
  }

  ///
  /// Body.
  ///

  &__body {
    @include shadow;
    height: 100%;
    overflow: auto;
  }

  &__body .Panel__body::after {
    @include container-margin($properties: padding-bottom);
    content: "";
    padding-right: 1px;
    width: 0;
  }

  .Panel {
    overflow-y: auto;
  }

  .Panel__body {
    @include responsive(flex-direction, row, column);

    @include mobile-only {
      width: 100%;
    }
  }

  .Panel .Panel__body .Toggle {
    @include mobile-only {
      @include margin("auto", "margin-right");
      margin-bottom: 0;
      white-space: nowrap;

      &:first-child {
        @include margin(true, "margin-left");
      }

      &:last-child {
        @include margin(true, "margin-right");
      }
    }
  }

  .Panel .Panel__body .CompassParty {
    padding-left: $grid-container-margin-base * 0.5;
    padding-right: $grid-container-margin-base * 0.5;
    width: auto !important;

    @include mobile-only {
      margin: 0;
    }
  }
}
