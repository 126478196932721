@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/typography";

.rightAlignment {
  text-align: right;
}

.SubSubCaption {
  @include h6;
  //@include color-secondary(background-color);
  border-radius: $grid-margin-1;
  display: inline-block;

  &[style*="background-color"] {
    padding: $grid-margin-3 $grid-margin-4;
  }

  &--center {
    text-align: center;
  }
}
