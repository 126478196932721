@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/typography";
@import "../../lib/i18n";
@import "../../lib/theme";
@import "../panel/constants";

.InfoButton {
  @include responsive(width, auto, 100%);
  @include button-theme(
    $background-color: transparent,
    $border-color: transparent,
    $border-size: 1px,
    $gradient: false,
    $scale: false,
    $shadow: false
  );
  align-items: center;
  appearance: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: $color-primary;
  display: inline-flex;
  font-size: 0;
  justify-content: center;
  overflow: visible;
  position: relative;

  @include tablet {
    @include container-margin($properties: "padding");
    background-color: $color-background;
    border-color: $panel-border-color;
    width: 100%;
  }

  > .Icon {
    @include color-background;
    color: $color-primary;

    @include mobile-only {
      @include rows(0.35, font-size);
      @include rows(0.5, height);
      @include rows(0.5, width);
    }

    @include tablet {
      font-size: 1.75rem;
    }
  }

  .Popover {
    @include tablet {
      margin-top: $grid-margin-4;
    }
  }
}
