@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";

.Footer {
  @include color-secondary(background-color);
  color: $color-secondary-contrast;
  mask: url("./footer-background.svg");
  mask-size: cover;
  width: 100%;

  &__body {
    @include rows(1, (padding-top, padding-bottom));
    @include container;
  }
}
