@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/util";
@import "~microscope-sass/lib/transform";

.Persona {
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  overflow: auto;
  width: 100%;

  &::before {
    content: "";
    display: block;
    height: 50%;
    width: 50%;
  }

  &__head {
    @include overlay;
    @include container-margin($properties: padding);
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 50%;
  }

  &__head .Title {
    font-family: $typography-font-family-base;
  }

  &__head .Image {
    text-align: center;
  }

  &__body {
    @include color-background;
    @include shadow($offset-y: -0.25rem);
    @include rows(0.5, margin-top);
    position: relative;
    z-index: 10;
  }

  &__body:before {
    @include color-background;
    @include center-h;
    @include rotate(45);
    @include rows(1, width);
    @include rows(1, height);
    @include rows(-0.5, margin-left);
    @include rows(-0.5, top);
    @include shadow($offset-x: -0.25rem, $offset-y: -0.25rem);
    content: "";
    display: block;
    z-index: -1;
  }

  &__body .Body {
    @include breakpoint(
      $max: math.div(1, 16) * 1040rem + 2 * $grid-container-margin +
        math.div(1, 16) * 80rem + math.div(1, 16) * 200rem
    ) {
      @include container-margin(normal, "padding-left");
      @include responsive(
        padding-right,
        $grid-container-margin,
        $grid-container-margin + math.div(80, 16) * 1rem
      );
    }
  }
}
