@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/typography";

.SubCaption {
  @include h5;
  @include color-primary;
  font-family: $typography-font-family-text;

  &--center {
    text-align: center;
  }

  &--black {
    color: $color-black;
  }

  &--primary {
    @include color-primary;
  }

  &--primary-contrast {
    @include color-background(color);
  }

  &--secondary {
    @include color-secondary;
  }

  &--tertiary {
    color: $color-tertiary;
  }
}
