@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";

.Compass {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin: 0;
  max-width: 100%;
  position: relative;
  width: 700px;

  // Set margin for zoomed browsers (that don't have layout height set).
  // Set min height for zoomed browsers (that don't have layout height set).
  @media (max-height: 567px) {
    min-height: 300px;
    @include rows(2, margin-bottom);
  }

  &--aspect {
    height: 0;
    padding-top: 100%;
  }

  &__svg {
    @include center-v;
    overflow: visible;

    @include tablet {
      max-height: calc(100vh - #{2 * $grid-row-height});
    }
  }

  &__caption {
    visibility: hidden;
  }

  &__compass {
    overflow: visible;
  }

  .Chip {
    @include margin(true, margin-top);
  }
}
