@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Header {
  @include color-background;
  @include rows(2);
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;

  ///
  /// Modifiers.
  ///

  &--border,
  &--shadow {
    @include tablet {
      outline: math.div(1, 16) * 1rem solid $color-gray;
    }
  }

  &--border {
    @include border;
  }

  &--border .Logo,
  &--border .HeaderButton,
  &--border .MenuButton {
    height: 2 * $grid-row-height - math.div(2 * 1, 16) * 1rem;
  }

  &--shadow {
    @include shadow;
  }

  ///
  /// Sections.
  ///

  &__section {
    align-items: center;
    display: flex;
    flex-grow: 0;
    height: 100%;
  }

  &__section:first-child:last-child {
    width: 100%;
  }

  ///
  /// Section--branding.
  ///

  &__section--branding .Logo {
    box-sizing: content-box;
    height: 100%;
    padding: 0 $grid-container-margin-base;

    @include laptop {
      display: flex;
      height: 2 * $grid-row-height - 2 * $grid-margin-3 !important;
      justify-content: center;
      padding: $grid-margin-3 $grid-container-margin-base;
      width: math.div(200, 16) * 1rem - 2 * $grid-container-margin-base;
    }
  }

  &--menu &__section--branding .Logo {
    @include hide-on-mobile(flex);
  }

  ///
  /// Section--grow.
  ///

  &__section--grow {
    flex-basis: 100%;
    flex-grow: 1;
    overflow-x: auto;
  }

  &--border &__section--grow,
  &--shadow &__section--grow {
    border-left: math.div(1, 16) * 1rem solid $color-gray;
    border-right: math.div(1, 16) * 1rem solid $color-gray;
  }

  &--menu &__section--grow {
    @include mobile-only {
      @include rtl-property(border-left, none);
    }
  }

  &__section--grow .Button:first-child {
    @include show-on-mobile;
    background-color: transparent;
    font-weight: bold;
    height: 2 * $grid-row-height - math.div(1, 16) * 2rem;
    width: 2 * $grid-row-height - math.div(1, 16) * 2rem;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  &__section--grow .HeaderButton {
    @include color-primary;
    width: 100%;
  }

  &__section--grow > .Button:first-child ~ .HeaderButton {
    @include mobile-only {
      background-color: transparent !important;
      background-image: none !important;
      color: inherit !important;

      .HeaderButton__label {
        color: inherit !important;
      }

      &:not(.HeaderButton--active) {
        display: none;
      }
    }
  }

  &--border &__section--grow .HeaderButton:not(:last-child),
  &--shadow &__section--grow .HeaderButton:not(:last-child) {
    @include rtl-property(
      border-right,
      math.div(1, 16) * 1rem solid $color-gray
    );
  }

  &__section--grow > .LanguageSwitcher {
    @include center-h;
  }

  ///
  /// Section--actions.
  ///

  &__section--actions {
    justify-content: flex-end;
  }

  &__section--actions .MenuButton {
    position: relative;
    z-index: 3000;
  }
}
