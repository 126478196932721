@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Radio {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  position: relative;

  &__input {
    @include rtl-property(left, 0);
    cursor: pointer;
    height: $grid-margin-6;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: $grid-margin-6;
    z-index: 10;
  }

  &__radio {
    border: 1px solid $color-gray-darker;
    border-radius: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    height: $grid-margin-6;
    position: relative;
    width: $grid-margin-6;
  }

  &__input:focus + &__radio,
  &__input:hover + &__radio {
    @include color-primary(border-color);
    @include shadow($color-info, $offset-y: 0);
  }

  &__checkmark {
    @include animate;
    @include color-primary(background-color);
    @include overlay;
    border-radius: 100%;
    transform: tr_scale(0);
  }

  &[style] &__checkmark {
    background-color: currentColor;
  }

  &__input:checked + &__radio &__checkmark {
    transform: tr_scale(0.8);
  }

  .Label {
    @include animate;
    @include body--small;
    cursor: pointer;
  }

  &--inside .Label {
    @include overlay;
    align-items: center;
    display: flex;
    height: $grid-margin-6;
    justify-content: center;
    line-height: 1;
    width: $grid-margin-6;
  }

  &--inside &__input:checked ~ .Label {
    @include color-background(color);
  }

  &--outside .Label {
    @include rtl-property(margin-left, $grid-margin-1);
  }
}
