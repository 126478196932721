@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

@mixin thumb {
  @include animate;
  @include color-primary(background-color);
  @include scale(0.8);
  appearance: none;
  border: none;
  border-radius: 100%;
  height: $grid-margin-6;
  width: $grid-margin-6;

  .Slider[style] & {
    background-color: currentColor;
  }
}

.Slider {
  cursor: pointer;
  font-size: 0;
  max-width: math.div(1, 16) * 440rem;
  position: relative;
  width: 100%;

  &__input {
    appearance: none;
    background-color: transparent;
    border: 1px solid $color-gray-darker;
    border-radius: $grid-margin-6;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    height: $grid-margin-6;
    margin: 0;
    outline: none;
    position: relative;
    width: 100%;
    z-index: 10;

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-webkit-slider-thumb {
      @include thumb;
    }

    &:disabled {
      pointer-events: none;
    }

    &:disabled::-moz-range-thumb {
      @include scale(0);
    }

    &:disabled::-webkit-slider-thumb {
      display: none;
    }
  }

  &:focus &__input,
  &:hover &__input,
  &__input:hover,
  &__input:focus {
    @include color-info(border-color);
    @include shadow($color-info, $offset-y: 0);
  }

  &__input:hover::-moz-range-thumb,
  &__input:focus::-moz-range-thumb {
    @include color-info(border-color);
    @include shadow($color-info, $offset-y: 0);
  }

  &__input:hover::-webkit-slider-thumb,
  &__input:focus::-webkit-slider-thumb {
    @include color-info(border-color);
    @include shadow($color-info, $offset-y: 0);
  }

  &__list {
    @include overlay;
    align-items: center;
    display: flex;
    height: $grid-margin-6;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    @include body;
    @include body--small;
    pointer-events: none;
    text-align: center;
    user-select: none;
    width: $grid-margin-6;
  }

  &__list-item--active {
    color: $color-background;
    position: relative;
    z-index: 20;
  }
}
