@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/typography";

.Input {
  @include tablet {
    width: 150px;
  }

  &__error-text,
  &__extra-text {
    @include body;
    @include body--small;
    display: block;
    margin-bottom: $typography-margin-base * 0.5;
  }

  &__error-text {
    @include color-danger;
  }

  &__extra-text {
    @include color-info;
  }

  &__input {
    @include border(all, $color-gray-darker, 1px);
    @include body;
    @include color-info(outline-color);
    @include ellipsis;
    @include rows(1, min-height);
    appearance: none;
    background-color: $color-gray;
    border-radius: $grid-margin-0;
    color: $typography-color-h;
    padding: 0 $grid-margin-2;
    width: 100%;
  }

  &::placeholder,
  &:placeholder-shown {
    color: scale_color($typography-color-text, $lightness: 40%);
  }
}
