@use "sass:math";

[data-tool-type="compareJustificationPage"] {
  .Col--middle .Body:first-child {
    height: math.div(1, 16) * 152rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Col--middle .Body:first-child + .JustificationTable {
    height: calc(100% - math.div(1, 16) * 152rem);
  }
}
