@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";
@import "../../lib/i18n";

.StatementFilter {
  @include responsive(position, fixed, sticky);
  @include responsive(flex-direction, row, column);
  bottom: 0;
  display: flex;
  flex-shrink: 1;
  left: 0;
  overflow: hidden;
  width: 100%;
  z-index: 10;

  @include tablet {
    &__body .Panel {
      mask: linear-gradient(
          to bottom,
          $color-background calc(100% - #{2 * $grid-row-height}),
          transparent
        )
        0 0/100% 100%;
    }

    &--scrolled-end &__body .Panel {
      mask: none;
    }
  }

  &__head {
    @include shadow;
    flex-shrink: 0;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &--scrolled-start &__head {
    box-shadow: none;
  }

  &--scrolled-start &__head {
    box-shadow: none;
  }

  &__head .Panel {
    @include mobile-only {
      @include container-margin(
        $properties: (
          padding-left,
          padding-right,
        )
      );
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .Panel__body {
    @include mobile-only {
      width: 100%;
    }
  }

  &__head .Panel__body {
    min-height: 0;
  }

  &__head .SubCaption {
    margin-bottom: 0 !important;

    @include mobile-only {
      @include container-margin(
        $properties: (
          padding-top,
          padding-bottom,
        )
      );
      @include ellipsis;
      box-sizing: border-box;
    }
  }

  &__head .Select {
    @include responsive(width, math.div(200, 16) * 1rem, 100%);
    box-sizing: border-box;
    margin-bottom: 0 !important;

    & + .Select {
      @include container-margin(
        $properties: (
          margin-left,
        )
      );
      @include container-margin(
        $properties: (
          margin-right,
        )
      );

      @include mobile-only {
        @include container-margin(
          $properties: (
            padding-top,
          )
        );
        @include container-margin(
          $properties: (
            padding-bottom,
          )
        );
      }
    }
  }

  &__head &__select-statements {
    @include show-on-mobile;
  }

  &__head .Select {
    box-sizing: border-box;
    flex-basis: 33%;
  }

  &__body {
    @include hide-on-mobile;
    overflow: auto;
    overflow-y: hidden;

    @include tablet {
      height: 100%;
      overflow-y: auto;
    }
  }

  .Panel {
    @include responsive(overflow-y, none, auto);
  }

  .Statement {
    @include responsive(width, auto, 100%);
    cursor: pointer;
    flex-shrink: 0;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    word-break: break-word;
  }

  .Statement__statement {
    color: $typography-color-text;
  }
}
