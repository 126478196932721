@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/grid";

.Container {
  @include container;
  max-width: math.div(1, 16) * 1140rem !important;

  &--big {
    max-width: math.div(1, 16) * 1440rem !important;
  }
}
