@import "../../settings";

.CardBody {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $grid-container-margin;
  position: relative;

  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
  }
}
