@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "../../lib/i18n";

.ComparativeEntityInformation {
  width: 100%;

  @include tablet {
    padding-top: $grid-margin-10;
  }

  .Body {
    @include responsive(flex-direction, column, row);
    display: flex;
  }

  .Body:first-child .Title {
    @include responsive(max-width, 100%, calc(100% - #{4 * $grid-row-height}));
  }

  ///
  /// Head.
  ///

  &__head {
    align-items: baseline;
    flex-shrink: 0;
    width: 128px;

    @include mobile-only {
      @include gutter(padding-bottom);
    }

    @include tablet {
      @include gutter(padding-right);
    }
  }

  &__head .Image,
  &__head .Image__image {
    height: auto;
    width: 100%;
    border-radius: 100%;
  }

  ///
  /// Body.
  ///

  &__body {
    position: relative;
    width: 100%;
  }

  &__body:first-of-type .Title {
    @include mobile-only {
      margin-bottom: 0 !important;
    }
  }

  &__body &__social {
    @include margin;
    display: flex;
  }

  &__body &__social {
    @include margin(true, gap);
  }

  &__body &__social .Icon--instagram {
    background-color: #ff0069 !important;
  }

  &__body &__social .Icon--facebook {
    background-color: #4267b2 !important;
  }

  &__body &__social .Icon--facebook {
    background-color: #4267b2 !important;
  }

  &__candidate-summary {
    mask: linear-gradient(
        to bottom,
        $color-background calc(100% - #{2em * $typography-line-height-text-big}),
        transparent
      )
      0 0/100% 100%;
    max-height: 5em * $typography-line-height-text-big;
    overflow: hidden;
  }

  &__body &__list {
    counter-reset: comparative-entity-information-list;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__body &__list-item {
    @include body;
    @include body--big;
    counter-increment: comparative-entity-information-list;
  }

  &__body &__list-item:before {
    @include rtl-property(margin-right, 0.5em);
    content: counter(comparative-entity-information-list);
    font-weight: bold;
    margin-right: 8px;
  }

  &__body .Button {
    @include mobile-only {
      width: 100%;
    }
  }
}
