@import "../settings";
@import "./string";
@import "~microscope-sass/lib/responsive";

@mixin rtl($property: null, $value-ltr: null, $value-rtl: null) {
  [dir="ltr"] & {
    #{$property}: $value-ltr;
  }

  &[dir="ltr"] {
    #{$property}: $value-ltr;
  }

  [dir="rtl"] & {
    @if $property {
      #{$property}: $value-rtl;
    }

    @content;
  }

  &[dir="rtl"] {
    @if $property {
      #{$property}: $value-rtl;
    }

    @content;
  }
}

@mixin rtl-property($property, $value, $property-rtl: null) {
  @if not $property-rtl {
    $property-rtl: str-replace($property, left, right);

    @if $property-rtl == $property {
      $property-rtl: str-replace($property, right, left);
    }
  }

  [dir="ltr"] & {
    #{$property}: $value;
  }

  &[dir="ltr"] {
    #{$property}: $value;
  }

  [dir="rtl"] & {
    #{$property-rtl}: $value;
  }

  &[dir="rtl"] {
    #{$property-rtl}: $value;
  }
}
