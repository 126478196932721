@import "../../settings";
@import "~microscope-sass/lib/typography";

.SubTitle {
  @include h2;

  &--center {
    text-align: center;
  }

  &--strong {
    font-weight: bold;
  }
}
