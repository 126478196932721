@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Modal {
  &__overlay {
    @include animate(
      opacity,
      $duration: $animation-default-duration-medium,
      $delay: $animation-default-duration-medium
    );
    @include overlay;
    animation: $animation-default-duration-medium * 2 Modal-visibility;
    animation-fill-mode: forwards;
    background-color: $transform-shadow-default-color;
    opacity: 0;
    position: fixed;
    visibility: visible;
    z-index: 2010;

    @keyframes Modal-visibility {
      99% {
        visibility: visible;
      }

      100% {
        visibility: hidden;
      }
    }
  }

  &--dark &__overlay {
    background-color: rgba(58, 63, 67, 0.85);
  }

  &--active &__overlay {
    @include animate(opacity, $duration: $animation-default-duration-medium);
    animation: none;
    opacity: 1;
    visibility: visible !important;
  }

  &:not(#{&}--active) {
    pointer-events: none;
  }

  &__modal {
    @include container(flex);
    @include shadow;
    background-color: $color-white;
    border: math.div(1, 16) * 1rem solid $color-gray;
    border-radius: $grid-margin-1;
    flex-direction: column;
    position: relative;
    visibility: visible;
    z-index: 1000;
  }

  &--animate-fast &__overlay,
  &--animate-fast &__modal {
    transition: $animation-default-duration-fast !important;
  }

  &--small#{&}--float &__modal {
    @include container--small;
  }

  &--smaller#{&}--float &__modal {
    @include container--small;
    max-width: $grid-container-size-small * 0.5 !important;
  }

  &--big#{&}--float &__modal {
    @include container--big;
  }

  &--fullscreen#{&}--float &__modal {
    border: none;
    box-shadow: none;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }

  &--active &__modal::before {
    @include animate(opacity, $duration: $animation-default-duration-medium);
    opacity: 1;
    visibility: visible;
  }

  &--float &__modal {
    @include animate(all, $duration: $animation-default-duration-medium);
    @include center-hv;
    @include translate(-50%, -150%);
    @include rows(2, min-height);
    max-height: calc(100% - #{2 * $grid-container-margin});
    opacity: 0;
    overflow: hidden; // ???
    position: fixed;
  }

  &--active#{&}--float &__modal {
    @include animate(all, $duration: $animation-default-duration-medium);
    @include translate(-50%, -50%);
    opacity: 1;
    position: fixed;
  }

  &--side &__modal {
    @include animate(transform, $duration: $animation-default-duration-medium);
    @include rtl-property(right, 0);
    @include rtl(transform, translateX(100%), translateX(-100%));
    height: 100%;
    max-width: $breakpoint-mobile-l;
    position: fixed;
  }

  &--active#{&}--side &__modal {
    @include animate(transform, $duration: $animation-default-duration-medium);
    @include translate(0);
  }

  &__header {
    @include container-margin(
      $properties: (
        padding-left,
        padding-right,
      )
    );
    @include shadow;
    align-items: center;
    display: flex;
    padding-bottom: $grid-margin-4;
    padding-top: $grid-margin-4;

    @include tablet {
      padding-left: 2 * $grid-margin-10;
      padding-right: 2 * $grid-margin-10;
    }
  }

  &__header > .Icon {
    @include rtl-property(margin-right, $typography-margin-base);
    flex-shrink: 0;
    height: $grid-margin-8;
    width: $grid-margin-8;
  }

  &__header > .IconButton {
    @include rtl-property(margin-left, $typography-margin-base);
    flex-shrink: 0;
    height: $grid-margin-8;
    width: $grid-margin-8;
  }

  &__header .SubCaption {
    width: 100%;
  }

  &__header .SubCaption:last-of-type {
    margin-bottom: 0;
  }

  &__body {
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

  &--small .Body {
    padding-bottom: $grid-container-margin;
    padding-top: $grid-container-margin;
  }

  ///
  /// Filled (use only for 1169)
  ///

  &--filled &__overlay {
    @include color-background;
  }

  &--filled &__modal {
    overflow-y: auto;
  }

  &--filled &__header {
    @include margin(true, margin-top, $value-mobile: 2 * $grid-margin-5);
    box-shadow: none;
    justify-content: center;
    padding: 0;
  }

  &--filled &__body {
    overflow: visible;
  }

  &--filled .SubCaption {
    @include h2;
    text-align: center;
  }

  &--filled .Text {
    text-align: center;
  }

  &--filled .FormControl:first-child {
    margin-top: 0;
  }
}
