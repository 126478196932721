@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";
@import "constants";

.Panel {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;

  @include tablet {
    &--horizontal &__body > *:not(:first-child) {
      @include margin(true, "margin-left");
    }

    &--vertical &__body > *:not(:first-child) {
      @include margin(true, "margin-top");
    }

    &--horizontal &__body > *:not(:last-child) {
      @include margin(true, "margin-right");
    }

    &--vertical &__body > *:not(:last-child) {
      @include margin(true, "margin-bottom");
    }

    &--vertical &__body .SubCaption--side {
      width: 100%;
    }
  }

  &--grow {
    @include tablet {
      height: 100%;
    }
  }

  &--pad {
    @include container-margin(
      $properties: (
        padding-top,
        padding-bottom,
      )
    );
  }

  &--solid {
    @include border(all, $size: 1px, $color: $panel-border-color);
    background-color: $color-gray-lighter;
  }

  &--shadow {
    @include border(all, $size: 1px, $color: $panel-border-color);
    @include shadow;
  }

  &--vertical {
    height: 100%;
  }

  &__body {
    @include container(flex);
    @include responsive(flex-direction, column, row);
    @include rows(1, min-height);
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
  }

  &--justify &__body {
    justify-content: space-between;
  }

  &--side &__body {
    justify-content: flex-start;
  }

  &--stretch &__body {
    justify-content: stretch;
  }

  &--space-around &__body {
    justify-content: space-around;
  }

  &--stretch &__body > * {
    flex-basis: 100%;
  }

  &--vertical &__body {
    flex-direction: column;
  }

  &:not(#{&}--smaller) &__body > * {
    @include mobile-only {
      @include margin;
      width: 100%;
    }
  }

  &--small &__body,
  &--smaller &__body {
    @include container--small;
  }

  &--smaller &__body {
    @include container-margin(normal, ("padding-left", "padding-right"));
    flex-direction: row;

    @include mobile-only {
      max-width: 100%;
      width: 100%;
    }

    @include tablet {
      @include border(left, $size: 1px, $color: $panel-border-color);
      @include border(right, $size: 1px, $color: $panel-border-color);
      max-width: math.div(1, 16) * 440rem + 2 * $grid-container-margin;
    }
  }

  &--big &__body {
    @include container--big;
  }

  &--bigger &__body {
    @include container--big;
    max-width: $breakpoint-laptop !important;
  }

  &--full &__body {
    max-width: calc(100% - #{2 * $grid-container-margin-big});
  }

  &:not(#{&}--pad) &__body {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  &--vertical .IconButton {
    width: 100%;
  }

  &--vertical .IconButton .Button {
    @include mobile-only {
      @include responsive(
        padding,
        $grid-margin-4 $grid-margin-4,
        $grid-container-margin $grid-margin-4
      );
      @include rtl(text-align, left, right);
    }
  }
}
