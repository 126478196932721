@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";

.Chip {
  @include body;
  @include body--small;
  @include color-background;
  align-items: center;
  border: 1px solid $color-gray-darker;
  border-radius: $grid-margin-2;
  color: $typography-color-h !important;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: $grid-margin-6;
  padding: 0 $grid-margin-2;
  position: relative;

  &:focus,
  &:hover {
    @include outline;
    transform: scale(1.025);
  }

  &:active {
    @media (pointer: coarse) {
      @include scale(0.95);
    }
    @media (pointer: fine) {
      @include scale(1);
    }
  }

  &[href],
  &--external {
    @include anchor(true);
    @include rtl-property(
      padding-right,
      $grid-margin-0 + $grid-margin-1 + 1.5rem
    );
  }

  .Icon {
    @include rtl-property(right, $grid-margin-1);
    justify-self: flex-end;
    position: absolute;
  }

  > a {
    color: inherit;
    text-decoration: inherit;
  }
}
