@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/util";

.Sponsors {
  @include color-background;
  @include shadow;
  outline: math.div(1, 16) * 1rem solid $color-secondary;
  overflow: hidden;
  width: 100%;

  &__list {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--justify &__list {
    justify-content: center;
  }

  &__list-item {
    flex-shrink: 0;
  }

  .Logo {
    @include rows(1);
    padding: $grid-margin-5 $grid-margin-8;
  }
}
