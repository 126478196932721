@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/typography";

.ToastStack {
  @include rtl-property(right, $typography-margin-base);
  bottom: $typography-margin-base;
  display: flex;
  flex-direction: column-reverse;
  max-width: calc(100% - #{2 * $typography-margin-base});
  position: fixed;

  // Set margin for zoomed browsers (that don't have layout height set).
  // Set min height for zoomed browsers (that don't have layout height set).
  @media (max-height: 567px) {
    @include rows(2, bottom);
  }

  .Toast {
    @include margin("auto", "margin-top");
    position: static;

    @include mobile-only {
      width: 100%;
    }
  }

  .Toast:not(.Toast--active) {
    animation: $animation-default-duration-medium * 4 ToastStack-display
      forwards;

    @keyframes ToastStack-display {
      100% {
        height: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
