@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/typography";

.Wysiwyg {
  @include wysiwyg;
  box-sizing: border-box;
  max-width: math.div(1, 16) * 650rem;
  padding: $grid-container-margin * 2 0;
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  p {
    @include body--big;
  }

  //
  // Size
  //

  &--big {
    max-width: math.div(1, 16) * 1040rem;
  }

  &--small {
    max-width: math.div(1, 16) * 252rem;
  }

  //
  // Alignment
  //

  &--centered {
    margin: 0 auto;
  }

  &--pad,
  &--pad-extra {
    padding-left: $grid-container-margin;
    padding-right: $grid-container-margin;
  }

  &--pad-extra {
    @include tablet {
      padding-left: 2 * $grid-margin-10;
      padding-right: 2 * $grid-margin-10;

      // Edge case: party info page.
      h1:first-child {
        margin-top: $grid-margin-10;
      }
    }
  }

  &--side {
    @include rtl(text-align, left);
  }

  &--center,
  &--center .Text {
    text-align: center !important;
  }

  //
  // Optional margins
  //

  &--margin {
    h1 {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h1-mobile,
        $typography-margin-h1-tablet,
        $typography-margin-h1-laptop,
        $typography-margin-h1-desktop
      );
    }

    h2 {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h2-mobile,
        $typography-margin-h2-tablet,
        $typography-margin-h2-laptop,
        $typography-margin-h2-desktop
      );
    }

    h3 {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h3-mobile,
        $typography-margin-h3-tablet,
        $typography-margin-h3-laptop,
        $typography-margin-h3-desktop
      );
    }

    h4 {
      @include margin(
        auto,
        margin-top,
        $typography-margin-h4-mobile,
        $typography-margin-h4-tablet,
        $typography-margin-h4-laptop,
        $typography-margin-h4-desktop
      );
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h4-mobile,
        $typography-margin-h4-tablet,
        $typography-margin-h4-laptop,
        $typography-margin-h4-desktop
      );
    }

    h5 {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h4-mobile,
        $typography-margin-h4-tablet,
        $typography-margin-h4-laptop,
        $typography-margin-h4-desktop
      );
    }

    h6 {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h5-mobile,
        $typography-margin-h5-tablet,
        $typography-margin-h5-laptop,
        $typography-margin-h5-desktop
      );
    }

    p {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-text-mobile,
        $typography-margin-text-tablet,
        $typography-margin-text-laptop,
        $typography-margin-text-desktop
      );
    }
  }

  //
  // Required margins.
  //

  h1 + button,
  h2 + button,
  h3 + button {
    @include margin(true, margin-top, $typography-margin-text * 1);
  }

  button + h1,
  button + h2,
  button + h3,
  p + h1,
  p + h2,
  p + h3 {
    @include margin(true, margin-top, $typography-margin-text * 2);
  }

  p + button {
    @include margin(true, margin-top, $typography-margin-text * 2);
  }

  button + p,
  h4 + p {
    @include margin(true, margin-top, $typography-margin-text * 2);
  }

  img {
    @include margin;
  }

  //
  // Specifics
  //

  // Group lead and title.
  &--margin h4 + h1 {
    @include margin(
      auto,
      margin-top,
      -$typography-margin-h4-mobile,
      -$typography-margin-h4-tablet,
      -$typography-margin-h4-laptop,
      -$typography-margin-h4-desktop
    );
  }
}
