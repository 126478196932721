@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";

.Row {
  @include container(flex, false);
  @include responsive(flex-direction, column, row);
  flex-shrink: 0;
  max-width: 100% !important;
  overflow: hidden;
  position: relative;

  &--center {
    justify-content: center;
  }

  &--grow {
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    //overflow: hidden;
  }

  &--top {
    position: sticky;
    top: 0;
    z-index: 2000;
  }

  &--overflow {
    overflow: visible;
  }

  &--overflow-ios {
    @include mobile-only {
      @supports (-webkit-touch-callout: none) {
        overflow: visible;
      }
    }
  }

  &--overflow-mobile {
    @include mobile-only {
      overflow: visible;
    }
  }
}
