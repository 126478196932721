@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/typography";

.LanguageSwitcher {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  &__language {
    @include body;
    @include rtl-property(border-right, 1px solid $color-gray-darker);
    cursor: pointer;
    padding: 0 $grid-margin-1;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__list_item--active &__language {
    font-weight: bold;
  }

  &__list_item:last-child &__language {
    @include rtl-property(border-right, none);
  }
}
