html,
body {
  margin: 0;
  padding: 0;
}

.App {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
