@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/responsive";

.Ptv {
  width: 100%;

  @include mobile-only {
    .Radio:not(:last-child) .Label {
      @include rtl-property(margin-right, $grid-margin-2);
    }
  }

  @include tablet {
    .Avatar {
      justify-content: flex-start;
    }

    .RadioGroup--normal {
      justify-content: flex-end;
    }
  }

  .Panel {
    border-top: 0;
  }

  &:nth-child(odd) .Panel {
    @include color-background;
  }

  .Avatar,
  .RadioGroup--normal {
    width: math.div($grid-container-size-big, 3);
  }
}
