@import "../../settings";
@import "../../lib/theme";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/typography";

.HeaderButton {
  @include animate;
  @include body;
  @include rows(2);
  @include button-theme(
    $background-color: $color-background,
    $text-color: $color-border,
    $text-color-active: $color-background,
    $gradient: "active",
    $scale: false,
    $shadow: false
  );
  align-items: center;

  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-flex;
  font-size: $grid-margin-3;
  font-weight: 600;
  justify-content: center;
  outline-offset: -2px;
  padding: 0;
  padding: $grid-margin-2;
  position: relative;
  text-align: center;
  text-decoration: none;

  &:active {
    border-bottom-color: $color-border;
    border-bottom-width: $typography-size-border;
  }

  &:hover {
    background-image: linear-gradient(
      to bottom,
      rgba($color-black, 0.2),
      rgba($color-black, 0.2)
    );
  }

  &--active {
    background-color: $color-primary;
  }

  &--active &__label {
    @include color-background(color);
  }
}
