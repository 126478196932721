@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";

.Ranking {
  @include animate(transform, $animation-default-duration-slow);
  @include container-margin(
    $properties: (
      padding-top,
      padding-bottom,
    )
  );
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;

  // Set margin for zoomed browsers (that don't have layout height set).
  & + &:last-child {
    @media (max-height: 567px) {
      @include rows(2, margin-bottom);
    }
  }

  .Avatar {
    align-items: flex-start;
  }

  .Caption {
    padding-top: $grid-margin-2;
  }

  .ProgressBar {
    @include absolute(
      $bottom: $typography-margin-base + $grid-container-margin
    );
    @include rtl-property(
      padding-left,
      math.div(64, 16) * 1rem + $typography-margin-base
    );
    //width: calc(100% - #{1 * $grid-container-margin});
  }

  .ProgressBar .ProgressBar__progressbar {
    height: $grid-margin-2;
    position: relative;
  }

  .ProgressBar .Caption {
    @include rows(1, width);
  }

  .ProgressBar::after {
    /* prettier-ignore */
    @include absolute($bottom: -1 * ($typography-margin-base + $grid-container-margin));
    border: 1px solid $color-gray;
    content: "";
    width: calc(
      100% - #{math.div(64, 16) * 1rem + $typography-margin-base + 1 *
        $grid-row-height + $typography-margin-base}
    );
  }
}
