@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/util";
@import "~microscope-sass/lib/transform";

.Card {
  @include color-background;
  @include container(flex);
  @include container-margin(normal, margin);
  @include shadow;
  border: math.div(1, 16) * 1rem solid $color-gray;
  border-radius: $grid-margin-1;
  display: grid;
  flex-direction: column;
  grid-template-areas: "card-header" "card-body" "card-footer" "card-toolbar";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  justify-content: space-between;
  padding: 0 !important;

  &--transparent {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  @include mobile-only {
    .CardHeader {
      grid-area: card-toolbar;
      padding-top: 0;
    }

    &__slider {
      grid-area: card-body;
    }

    .CardFooter {
      grid-area: card-footer;
    }
  }

  &--small {
    @include container--small;
  }

  &--big {
    @include container--big;
    @include responsive(
      height,
      calc(100% - #{2 * $grid-container-margin}),
      math.div(1, 16) * 580rem
    );
  }

  .CardHeader,
  .CardFooter {
    @include breakout-padding;
    flex-shrink: 0;
  }

  &--big .CardHeader,
  &--big .CardBody,
  &--big .CardFooter {
    @include responsive(
      padding-left,
      1 * $grid-container-margin,
      3 * $grid-container-margin
    );
    @include responsive(
      padding-right,
      1 * $grid-container-margin,
      3 * $grid-container-margin
    );
  }

  &--card-header-bottom .CardHeader {
    grid-area: card-footer;
  }

  .CardBody {
    align-items: center;
    height: 100%;
    text-align: center;
  }

  &__slider {
    display: flex;
    flex-basis: 100%;
    overflow: hidden;
    position: relative;
  }

  &__slider .ProgressBar {
    @include show-on-mobile;
    @include absolute($top: 0, $left: 0);
    width: 100%;
  }

  &__slide {
    @include translate(0);
    flex-shrink: 0;
    position: relative;
    width: 100%;
    z-index: 200;
  }

  .Modal .Modal__overlay {
    position: absolute;
    z-index: 100;
  }

  .Modal--active .Modal__overlay {
    z-index: 300;
  }

  .Modal .Modal__overlay .Modal__modal {
    top: 50%;

    @include mobile-only {
      max-width: calc(100% - #{4 * $grid-container-margin});
    }

    @include tablet {
      position: absolute;
    }
  }
}
