@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/typography";

.CardHeader {
  @include color-background;
  border-bottom: math.div(1, 16) * 1rem solid $color-gray;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  z-index: 9000;

  &--pad {
    padding: $grid-container-margin $grid-container-margin;
  }

  &--transparent {
    background-color: transparent;
    border-bottom-color: transparent;
  }
}
