@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Toast {
  @include animate($duration: $animation-default-duration-slow);
  @include color-primary(background-color);
  @include responsive(
    width,
    calc(100% - #{2 * $typography-margin-base}),
    math.div(1, 16) * 320rem
  );
  @include rtl-property(right, $typography-margin-base);
  @include shadow($color: rgba($color-black, 0.4));
  @include translate($y: 100%);
  background-image: linear-gradient(
    to bottom,
    transparent,
    rgba($color-black, 0.2)
  );
  border-radius: $grid-margin-1;
  bottom: $typography-margin-base;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding: $grid-margin-2 $grid-margin-8 $grid-margin-2 $grid-margin-4;
  position: fixed;
  transform-origin: bottom;
  z-index: 10000;

  &--active {
    @include translate($y: 0%);
    opacity: 1;
  }

  &--interactive {
    cursor: pointer;
  }

  &__body > .Title,
  &__body > .SubTitle,
  &__body > .SubSubTitle,
  &__body > .Caption,
  &__body > .SubCaption,
  &__body > .SubSubCaption,
  &__body > .Text {
    @include color-background(color, true);
    @include margin;
  }

  &[style] &__body .Title,
  &[style] &__body .SubTitle,
  &[style] &__body .SubSubTitle,
  &[style] &__body .Caption,
  &[style] &__body .SubCaption,
  &[style] &__body .SubSubCaption,
  &[style] &__body .Text {
    color: currentColor;
  }

  > .Button {
    @include absolute($top: $grid-margin-2, $right: $grid-margin-2);
    @include color-background(color);
    cursor: pointer;
  }
}
