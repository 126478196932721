@import "../../settings";
@import "~microscope-sass/lib/responsive";

.Avatar {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .Image {
    flex-shrink: 0;
    height: auto;
  }

  .Image__image {
    border-radius: 100%;
    //border: 1px solid $color-gray-dark;
  }

  .Caption,
  .SubCaption {
    box-sizing: border-box;
    padding: $grid-margin-4;
  }

  &:not(#{&}--color).Caption,
  &:not(#{&}--color).SubCaption {
    color: $typography-color-h;
  }
}
