@import "../../settings";
@import "../../lib/i18n";

.Image {
  display: inline-block;
  font-size: 0;
  height: 100%;

  &--left {
    @include rtl(float, left, right);
  }

  &--right {
    @include rtl(float, right, left);
  }

  &--height-set {
    height: auto;
  }

  &__image {
    max-width: 100%;
  }

  &__image:not([height]) {
    height: 100%;
  }

  &--fill &__image {
    object-fit: fill;
  }

  &--contain &__image {
    object-fit: contain;
  }

  &--cover &__image {
    object-fit: cover;
  }

  &--object-position-center &__image {
    object-position: center;
  }

  &--object-position-left &__image {
    @include rtl(object-position, left, right);
  }

  &--object-position-right &__image {
    @include rtl(object-position, right, left);
  }
}
