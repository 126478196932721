@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

$button-height: $grid-container-margin * 2 + $typography-font-size-text *
  $typography-line-height-text;

.ProgressIndicator {
  position: relative;

  @include rtl {
    .Icon {
      @include scale(-1);
    }
  }

  @include mobile-only {
    box-sizing: border-box;
    overflow: hidden;

    &--active {
      bottom: 0;
      left: 0;
      overflow: visible;
      padding: $grid-container-margin;
      position: fixed;
      width: 100%;
    }

    &__list {
      @include absolute(
        $left: 0,
        $bottom: $grid-container-margin + $grid-row-height +
          $grid-container-margin
      );
      @include animate;
      box-sizing: border-box;
      opacity: 0;
      padding: $grid-container-margin;
      touch-action: none;
      transform: tr_translate(null, 100%);
      width: 100%;
    }

    &--active &__list {
      @include translate(null, 0);
      opacity: 1;
    }

    &__list::before {
      @include center-h;
      @include shadow;
      background-color: $color-gray;
      box-sizing: content-box;
      content: "";
      height: calc(100% + #{$grid-container-margin + $button-height});
      outline: 1px solid $color-border-secondary;
      top: 0;
      width: 100%;
    }

    &__list-item--completed &__link {
      @include color-background(color);
      background-color: $color-gray-darker;
      position: relative;
      z-index: 1000;
    }

    &__actions {
      display: flex;
      height: math.div(1, 16) * 10rem * $typography-line-height-text-small * 3;
      justify-content: space-between;
      position: relative;

      @media (min-height: math.div(1, 16)*568rem) {
        height: $button-height;
      }
    }

    .Button {
      @include animate;
      @include body--small;

      @media (max-height: math.div(1, 16)*567rem) {
        font-size: math.div(1, 16) * 10rem;
        height: math.div(1, 16) * 10rem * $typography-line-height-text-small * 3;
        padding-bottom: 0;
        padding-top: 0;
      }
      @media (min-height: math.div(1, 16)*568rem) {
        height: $button-height;
      }
    }

    .Button:first-child {
      @include rtl(margin-right, $grid-margin-2);
      width: 33%;
    }

    &--active .Button {
      position: absolute;
    }

    &--active .Button:first-child {
      @include scale(0);
    }

    .Button:last-child {
      width: 66%;
    }

    &--active .Button:last-child {
      right: 0;
      width: 100%;
    }
  }

  @include tablet {
    display: flex;
    justify-content: space-between;

    &__actions {
      display: none;
    }

    &__list {
      padding: 0;
    }
  }

  ///
  &--square &__list-item &__link {
    background-color: transparent;
    color: $color-primary;
    outline-color: $color-primary;
  }

  &--square &__list-item--completed &__link {
    border-color: $color-primary;
  }

  &--square &__list-item--active &__link {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-background;
    outline-color: $color-secondary;
  }

  &--circle &__list-item--completed &__link {
    background-color: $color-secondary;
    color: $color-secondary-contrast;
    outline-color: $color-primary;
  }

  &--circle &__list-item--active &__link {
    background-color: $color-primary;
    color: $color-background;
    outline-color: $color-secondary;
  }
  ///

  .IconButton {
    @include hide-on-mobile;
  }

  &--square .IconButton {
    @include tablet {
      align-items: center;
      display: flex;
      height: $grid-margin-10;
      margin: $grid-margin-1;
      width: $grid-margin-10;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
  }

  //&--wrap &__list {
  //}

  &--nowrap &__list {
    @include tablet {
      @include absolute($left: $grid-margin-10 + $grid-margin-1);
      flex-wrap: nowrap;
      justify-content: flex-start;
      mask: linear-gradient(
        90deg,
        transparent,
        black 10%,
        black 90%,
        transparent
      );
      mask-size: cover;
      overflow: hidden;
      padding-left: calc(50% - (#{$grid-margin-10 * 1.5 + $grid-margin-1 * 2}));
      width: calc(50% - (#{$grid-margin-10 * 1.5 + $grid-margin-1 * 2}));
    }
  }

  &__list-item {
    @include animate;
    margin: $grid-margin-0 * 0.5;
    text-align: center;
  }

  &--square &__list-item {
    margin: $grid-margin-1;
  }

  &__list-item--completed &__link {
    background-color: $color-secondary;
  }

  &__link {
    @include body;
    @include body--small;
    align-items: center;
    border: math.div(1, 16) * 1rem solid;
    border-radius: 100%;
    color: $typography-color-text;
    display: flex;
    height: $grid-margin-6;
    justify-content: center;
    position: relative;
    text-decoration: none;
    touch-action: none;
    -webkit-touch-callout: none;
    user-select: none;
    user-select: none;
    width: $grid-margin-6;
  }

  &__link::before {
    @include overlay;
    content: "";
    width: $grid-margin-8;
  }

  &__link:focus,
  &__link:hover {
    color: $color-black;
  }

  &__link:focus {
    outline: 2px solid;
    transform: scale(1.2);
  }

  &--circle &__link {
    border-color: transparent;
  }

  &--square &__link {
    @include color-primary;
    background-color: transparent;
    border-color: $color-gray;
    border-width: math.div(1, 16) * 1rem;
    font-family: $typography-font-family-h;
    font-weight: $typography-font-weight-h6;

    @include tablet {
      border-radius: $grid-margin-1;
      height: $grid-margin-10;
      width: $grid-margin-10;
    }
  }

  &--square &__list-item--completed &__link {
    background-color: $color-gray;
  }

  &__list-item--active &__link {
    @include color-background(color);
    @include color-info(background-color);
  }

  &--circle &__list-item--active &__link {
    text-decoration: underline;
  }
}
