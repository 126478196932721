@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "../../lib/theme";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/transform";

.MenuButton {
  @include rows(2);

  @include button-theme(
    $background-color: $color-white,
    $background-color-hover: $color-gray,
    $background-color-active: scale_color($color-white, $lightness: -1%),
    $border-size: 0,
    $gradient: false,
    $scale: false,
    $shadow: false
  );
  animation: $animation-default-duration-medium MenuButton-z-index;
  animation-fill-mode: forwards;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: inherit !important;
  cursor: pointer;
  max-height: 100%;
  outline-offset: -2px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 2 * $grid-row-height - math.div(1, 16) * 1rem;
  z-index: 1010;

  @include rtl {
    @include scale(-1);
  }

  &--active {
    @include animate($delay: $animation-default-duration-medium);
    animation: none;
    z-index: 1010;
  }

  &__bars,
  &__bars::before,
  &__bars::after {
    @include animate($duration: $animation-default-duration-medium);
    @include border($color: $color-primary);
    @include center-hv;
    display: block;
    width: 2 * $grid-margin-2 + 3 * $typography-size-border;
  }

  &__bars::before,
  &__bars::after {
    content: "";
    transform-origin: right;
  }

  &__bars::before {
    top: -$grid-margin-2 + $typography-size-border;
  }

  &__bars::after {
    top: $grid-margin-2;
  }

  &--active &__bars {
    width: 0;
  }

  &--active &__bars::before,
  &--active &__bars::after {
    top: 0;
    width: (2 * $grid-margin-2 + 3 * $typography-size-border) * 0.5;
  }

  &--active &__bars::before {
    transform: tr_translate(-50%) tr_rotate(45);
  }

  &--active &__bars::after {
    transform: tr_translate(-50%) tr_rotate(-45);
  }

  &__label {
    opacity: 0;
    position: absolute;
  }

  @keyframes MenuButton-z-index {
    99% {
      z-index: 1010;
    }

    100% {
      z-index: 0;
    }
  }
}
