@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/typography";

.Select {
  @include tablet {
    width: 150px;
  }
  &__extra-text {
    @include body;
    @include body--small;
    @include color-primary;
    display: block;
    margin-bottom: $typography-margin-base * 0.5;
  }

  &__select {
    @include border(all, $color-gray-darker, 1px);
    @include body;
    @include ellipsis;
    @include rtl-property(padding-right, $grid-margin-3 * 2 + $grid-margin-2);
    @include rtl-property(padding-left, $grid-margin-2);
    @include rows(1, height);
    @include rtl(
      background-position,
      center right $grid-margin-2,
      center left $grid-margin-2
    );
    appearance: none;
    background-color: $color-gray;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii02MjU1IC03MDA0LjQzNiAxNi43NjkgMTAuMTAzIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjM2EzZjQzOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBpZD0iUGF0aF8xODU3IiBkYXRhLW5hbWU9IlBhdGggMTg1NyIgY2xhc3M9ImNscy0xIiBkPSJNOC4yOSwxNi43NjksMCw4LjQ4MSw4LjQ4MywwLDEwLjEsMS42MTksMy4yNzgsOC40ODFsNi42NzEsNi42MzFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjI1NSAtNjk5NC4zMzMpIHJvdGF0ZSgtOTApIi8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: $grid-margin-3;
    border-radius: $grid-margin-0;
    padding: 8px;
    width: 100%;
  }
  &__select[multiple] {
    overflow-y: auto;
  }

  &__select[data-value] {
    color: $typography-color-h;
  }
}
