@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";

.Toggle {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;

  &--stretch {
    width: 100%;
  }

  &__input {
    @include absolute($top: 0, $left: 0);
    cursor: pointer;
    height: $grid-margin-6;
    margin: 0;
    opacity: 0;
    width: $grid-margin-6;
    z-index: 10;
  }

  &__toggle {
    @include animate(
      background-color,
      $duration: $animation-default-duration-medium
    );
    background-color: $color-gray-darker;
    border-radius: $grid-margin-5;
    box-sizing: border-box;
    cursor: pointer;
    flex-shrink: 0;
    height: $grid-margin-5;
    position: relative;
    width: $grid-margin-8;
  }

  &__input:checked + &__toggle {
    @include color-primary(background-color);
  }

  &__input:focus + &__toggle,
  &__input:hover + &__toggle {
    @include color-primary(border-color);
    @include shadow($color-info, $offset-y: 0);
  }

  &__checkmark {
    @include animate($duration: $animation-default-duration-medium);
    @include center-v;
    @include color-background(background-color);
    @include rtl-property(left, $grid-margin-0);
    @include shadow(
      $offset-x: 0,
      $offset-y: 0,
      $blur: $grid-margin-1,
      $spread: $grid-margin-1
    );
    border-radius: 100%;
    height: $grid-margin-4;
    width: $grid-margin-4;
  }

  &__input:checked + &__toggle &__checkmark {
    @include rtl-property(left, $grid-margin-4 - $grid-margin-0);
  }

  &__input:checked + &__toggle[style] &__checkmark {
    background-color: currentColor;
  }

  .Label {
    @include animate($duration: $animation-default-duration-medium);
    @include body--small;
    @include rtl-property(margin-right, $typography-margin-base);
    cursor: pointer;
  }
}
