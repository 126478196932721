@import "../../settings";
@import "~microscope-sass/lib/typography";

.Label {
  @include body;
  @include body--big;
  color: $typography-color-text;

  &--center {
    text-align: center;
  }
}
