@import "../../settings";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/typography";

.IconButton {
  align-items: center;
  display: flex;
  justify-content: center;

  .Button {
    width: 100%;

    @include tablet {
      align-items: center;
      flex-direction: column;
    }
  }

  .Button *:first-child {
    @include margin("auto", "margin-right");

    @include tablet {
      @include margin("auto", "margin-bottom");
      margin-right: 0 !important;
    }
  }

  .Icon {
    @include body--big;
    flex-shrink: 0;
  }

  &--small .Icon {
    margin-bottom: 0 !important;
  }

  .Text {
    width: 100%;
  }
}
