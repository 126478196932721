@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/typography";

.RadioGroup {
  @include responsive(flex-wrap, wrap, nowrap);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mobile-only {
    @include rtl-property(padding-right, 0);
    display: grid;
    grid-template-columns: repeat(5, auto);
    justify-content: space-between;

    @include breakpoint($breakpoint-mobile-m) {
      grid-template-columns: repeat(10, auto);
    }

    .Radio {
      margin-left: 0;
      margin-right: 0 !important;

      @include breakpoint($breakpoint-mobile-m) {
        margin-bottom: 0;
      }
    }

    .Radio--outside {
      grid-column-end: span 2.5;

      @include breakpoint($breakpoint-mobile-m) {
        grid-column-end: span 2;
      }
    }
  }

  .Radio {
    @include margin("auto", "margin-right");

    @include rtl() {
      @include margin("auto", "margin-left");
      margin-right: 0;
    }

    @include mobile-only {
      @include margin(true, "margin-bottom");
    }
  }

  .Radio--outside {
    @include margin("auto", "margin-right", $value-mobile: $grid-margin-10);

    @include rtl {
      @include margin("auto", "margin-left", $value-mobile: $grid-margin-10);
      margin-right: 0;
    }
  }
}
