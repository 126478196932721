@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/typography";

.Text {
  @include body;
  white-space: pre-line;

  &--center {
    text-align: center;
  }

  &--color-primary {
    @include color-primary;
  }

  &--color-primary-contrast {
    @include color-background(color);
  }

  &--color-secondary {
    @include color-secondary;
  }

  &--color-tertiary {
    color: $color-tertiary;
  }

  &--side {
    @include rtl(text-align, left, right);
  }

  &--small {
    @include body--small;
  }

  &--big {
    @include body--big;
  }

  &--muted {
    color: scale-color($typography-color-text, $lightness: 20%);
  }

  &--strong {
    color: scale-color($typography-color-text, $lightness: -20%);
  }

  &--wysiwyg {
    @include wysiwyg;
  }

  a {
    @include color-info;
  }
}
