@import "../../settings";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/transform";

.Legend {
  @include color-secondary;
  @include gutter(padding-left, 0.5);
  @include gutter(padding-right, 0.5);
  box-sizing: border-box;
  height: 0;
  margin: $grid-margin-5 * 2 0;
  position: relative;
  width: 100%;
  z-index: 10;

  // Special case for hero followed by container with legend in column.
  .Row + .Container .Row:first-child .Col & {
    margin-top: 0;
  }

  @include mobile-only {
    text-align: center;
  }

  &:before {
    @include border(
      $position: top,
      $color: currentColor,
      $size: $grid-margin-1
    );
    @include gutter(margin-left, 0.5);
    @include overlay;
    content: "";
    width: calc(100% - #{1 * $grid-gutter-base});
  }

  &__body {
    @include translate($y: -50%);
    background-color: currentColor;
    mask: url("./legend-background.svg");
    mask-size: cover;
    background-size: cover;
    display: inline-block;
    padding: $grid-margin-5 $grid-margin-8;
  }

  &__body > * {
    color: $color-secondary-contrast;
  }
}
