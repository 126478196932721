@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/typography";

.Body {
  box-sizing: border-box;
  max-width: math.div(1, 16) * 650rem;
  padding: $grid-container-margin * 2 0;
  width: 100%;

  //
  // Size
  //

  &--big {
    max-width: math.div(1, 16) * 1040rem;
  }

  &--small {
    max-width: math.div(1, 16) * 252rem;
  }

  //
  // Alignment
  //

  &--centered {
    margin: 0 auto;
  }

  &--pad,
  &--pad-extra {
    padding-left: $grid-container-margin;
    padding-right: $grid-container-margin;
  }

  &--pad-extra {
    @include tablet {
      padding-left: 2 * $grid-margin-10;
      padding-right: 2 * $grid-margin-10;
    }
  }

  &--side {
    @include rtl(text-align, left);
  }

  &--center,
  &--center .Text {
    text-align: center !important;
  }

  //
  // Optional margins
  //

  &--margin {
    .Title {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h1-mobile,
        $typography-margin-h1-tablet,
        $typography-margin-h1-laptop,
        $typography-margin-h1-desktop
      );
    }

    .SubTitle {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h2-mobile,
        $typography-margin-h2-tablet,
        $typography-margin-h2-laptop,
        $typography-margin-h2-desktop
      );
    }

    .SubSubTitle {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h3-mobile,
        $typography-margin-h3-tablet,
        $typography-margin-h3-laptop,
        $typography-margin-h3-desktop
      );
    }

    .Lead {
      @include margin(
        auto,
        margin-top,
        $typography-margin-h4-mobile,
        $typography-margin-h4-tablet,
        $typography-margin-h4-laptop,
        $typography-margin-h4-desktop
      );
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h4-mobile,
        $typography-margin-h4-tablet,
        $typography-margin-h4-laptop,
        $typography-margin-h4-desktop
      );
    }

    .Caption {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h4-mobile,
        $typography-margin-h4-tablet,
        $typography-margin-h4-laptop,
        $typography-margin-h4-desktop
      );
    }

    .SubCaption {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-h5-mobile,
        $typography-margin-h5-tablet,
        $typography-margin-h5-laptop,
        $typography-margin-h5-desktop
      );
    }

    .Text {
      @include margin(
        auto,
        margin-bottom,
        $typography-margin-text-mobile,
        $typography-margin-text-tablet,
        $typography-margin-text-laptop,
        $typography-margin-text-desktop
      );
    }
  }

  //
  // Required margins.
  //

  *[class*="Title"] + .Button {
    @include margin(true, margin-top, $typography-margin-text * 1);
  }

  .Button + *[class*="Title"],
  .Text + *[class*="Title"] {
    @include margin(true, margin-top, $typography-margin-text * 2);
  }

  .Text--normal + .Text--small {
    @include margin(true, margin-top, $typography-margin-text * 2);
  }

  .Text + .Button {
    @include margin(true, margin-top, $typography-margin-text * 2);
  }

  .Toolbar {
    @include margin;
  }

  .Button + .Text,
  .Lead + .Text {
    @include margin(true, margin-top, $typography-margin-text * 2);
  }

  .Image {
    @include margin;
  }

  //
  // Specifics
  //

  // Group lead and title.
  &--margin .Lead + .Title {
    @include margin(
      auto,
      margin-top,
      -$typography-margin-h4-mobile,
      -$typography-margin-h4-tablet,
      -$typography-margin-h4-laptop,
      -$typography-margin-h4-desktop
    );
  }

  // First direct child is icon button, assume close button.
  > .IconButton:first-child {
    @include rtl(float, right, left);
  }
}
