@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/typography";

.Menu {
  background-color: $color-gray-lighter;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &,
  &__header,
  &__body {
    background-color: $color-gray-lighter;
  }

  &__header {
    @include border($color: $color-gray-darker);
    flex-shrink: 0;
    padding: $grid-margin-10 $grid-margin-10 0;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &__heeader .LanguageSwitcher {
    position: absolute;
    top: $typography-margin-base;
  }

  &__header .Caption {
    margin: $grid-margin-5 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    @include border($color: $color-gray, $size: math.div(1, 16) * 1rem);
    position: relative;
  }
}
