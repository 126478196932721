@import "../../settings";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/responsive";

.Title {
  @include h1;

  &--center {
    text-align: center;
  }

  &--strong {
    font-weight: bold;
  }

  &:not(#{&}--wrap) {
    @include tablet {
      white-space: nowrap;
    }
  }
}
