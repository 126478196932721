@import "../../settings";

@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";

.Skiplinks {
  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    @include absolute($top: 0, $left: 0);
    @include anchor;
    color: black;
    opacity: 0;
    pointer-events: none;
    text-shadow: white;
    z-index: 9999;

    &:focus {
      background-color: white;
      border: 1px solid black;
      opacity: 1;
      pointer-events: auto;
    }
  }
}
