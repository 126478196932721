@import "../../settings";
@import "~microscope-sass/lib/typography";

.Referendum {
  width: 100%;

  &__svg {
    height: 160px;
    width: 100%;
  }

  &__svg text {
    @include mobile-only {
      font-size: 0.7rem;
    }
  }
}
