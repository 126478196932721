@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";

.StatementCard {
  &--no-margin {
    margin: 0;
  }

  &--explanation-active .CardBody,
  &--explanation-active .Statement {
    background-color: rgb(252, 252, 252);
  }

  .CardBody {
    padding-left: 0;
    padding-right: 0;

    @include mobile-only {
      @include gutter(padding-bottom, 0.25);
    }
  }

  // Counter.
  .CardBody > .Text:first-child {
    @include absolute($top: $grid-margin-1, $right: 0);
    font-weight: bold;
  }

  &__counter-label {
    @include color-primary;
  }

  &--button-style-square &__counter,
  &--button-style-square .Segment .Button--open {
    font-family: Lora;
  }

  &--explanation-active .Statement__explanation {
    @include color-background;
  }

  &--pad .Statement__statement,
  &--pad .Statement__explanation,
  &--pad .Segment {
    @include responsive(
      padding-left,
      1 * $grid-container-margin,
      4 * $grid-container-margin
    );
    @include responsive(
      padding-right,
      1 * $grid-container-margin,
      4 * $grid-container-margin
    );
    box-sizing: border-box;
  }

  .Segment {
    @include animate;
    flex-shrink: 0;
    transform-origin: bottom;
  }

  &--explanation-active .Segment {
    height: 0;
    opacity: 0;
  }

  .Modal .SubCaption {
    @include color-primary;
  }

  .Modal .Icon {
    @include rtl-property(margin-right, 0.5em);
  }

  &.Card--card-header-bottom .CardHeader {
    @include tablet {
      padding-top: $grid-margin-10;
    }
  }
}
