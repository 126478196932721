@import "../settings";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/util";
@import "../lib/string";

/// Apply a theme to a button.
@mixin button-theme(
  $name: null,
  $background-color: $color-secondary,
  $background-color-hover: $background-color,
  $background-color-active: $background-color-hover,
  $border-color: transparent,
  $border-color-hover: $border-color,
  $border-color-active: $border-color-hover,
  $border-size: $typography-size-border,
  $text-color: $typography-color-text,
  $text-color-hover: $text-color,
  $text-color-active: $text-color-hover,
  $gradient: false,
  $scale: 1.05,
  $shadow: true
) {
  $selector: &;

  // Optional modifier by specifying theme.
  @if $name {
    $selector: #{&}--#{$name};
  }

  /* stylelint-disable max-nesting-depth */
  @at-root {
    #{$selector} {
      @include border(all, $border-color, $border-size);
      background-color: $background-color;
      background-origin: border-box;
      color: $text-color;

      @if $gradient == true {
        background-image: linear-gradient(
          to bottom,
          transparent,
          rgba($color-black, 0.2)
        );

        &[style*="background-color: transparent"] {
          background-image: none;
        }
      }

      // Hover.
      &[class*="--active"]:not(:disabled),
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled) {
        cursor: pointer;

        @if $scale {
          @media (pointer: fine) {
            @include scale($scale);
          }
        }

        @if $shadow {
          @include shadow;
        }
      }

      &:hover:not(:disabled) {
        @if $background-color-hover != $background-color {
          background-color: $background-color-hover;
        }

        @if $text-color-hover != $text-color {
          color: $text-color-hover;
        }

        @if $border-color-hover != $border-color {
          border-color: $border-color-hover;
        }

        @if $gradient == true or $gradient == "hover" {
          background-image: linear-gradient(
            to bottom,
            transparent,
            rgba($color-black, 0.2)
          );

          &[style*="background-color: transparent"] {
            background-image: none;
          }
        }
      }

      // Focus.
      &:focus:not(:disabled) {
        outline-color: $color-info;
      }

      // Active.
      &[class*="--active"]:not(:disabled),
      &:active:not(:disabled) {
        @if $background-color-active != $background-color {
          background-color: $background-color-active;
        }

        @if $text-color-active != $text-color {
          color: $text-color-active;
        }

        @if $border-color-active != $border-color {
          border-color: $border-color-active;
        }

        @if $gradient == true or $gradient == "hover" or $gradient == "active" {
          background-image: linear-gradient(
            to bottom,
            transparent,
            rgba($color-black, 0.2)
          );

          &[style*="background-color: transparent"] {
            background-image: none;
          }
        }
      }

      &:active:not(:disabled) {
        @if $scale {
          @media (pointer: coarse) {
            @include scale(0.95);
          }
          @media (pointer: fine) {
            @include scale(1);
          }
        }

        &::after {
          @include overlay;
          background-color: rgba($color-black, 0.2);
          content: "";
        }
      }
    }
  }
  /* stylelint-enable max-nesting-depth */
}
