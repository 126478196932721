@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "../../lib/number";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";

.Statement {
  @include color-background;
  @include rtl(text-align, left, right);
  border: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: relative;
  width: 100%;

  @include mobile-only {
    margin-bottom: 0;
    margin-top: 0;
    //padding: $grid-container-margin 0;
  }

  &--active {
    @include color-primary(background-color);
    background-image: linear-gradient(
      to bottom,
      transparent,
      rgba($color-black, 0.2)
    );
  }

  &--explanation-active {
    justify-content: flex-start;
  }

  &--center &__statement {
    justify-content: center;
  }

  &--stretch {
    height: 100%;
  }

  &--toggle {
    cursor: pointer;
  }

  &--transparent {
    background-color: transparent;
  }

  &--big {
    @include tablet {
      padding-bottom: 2 * $grid-container-margin-base;
    }
  }

  &--medium {
    @include mobile-only {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &--small {
    @include body;
    @include body--small;
    overflow: hidden;
  }

  &__statement {
    @include animate;
    //@include center-v;
    @include h1;
    box-sizing: border-box;
    display: flex;
    width: 100%;

    @include mobile-only {
      font-size: $typography-font-size-h4;
    }
  }

  &--active &__statement {
    font-weight: bold;
  }

  &--active &__statement:not([style]) {
    @include color-background(color, true);
  }

  &--big &__statement {
    @include rtl(text-align, left, right);
  }

  &--small &__statement {
    @include container-margin($properties: padding);
    @include body;
  }

  &--medium &__statement {
    @include h2;
    text-align: center;

    @include mobile-only {
      font-size: $typography-font-size-h5;
    }
  }

  &--strong &__statement {
    font-weight: bold;
  }

  &--explanation-active &__statement {
    @include h3;
    top: 0;
  }

  &--explanation-active .IconButton .Button {
    font-size: $typography-font-size-h1 !important;
  }

  .IconButton {
    @include color-primary;
    @include rtl(float, left, right);
    font-size: inherit;
  }

  .IconButton .Button {
    font-size: inherit;
  }

  &__explanation {
    @include margin(true, padding-bottom);
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }

  &__explanation .Text {
    @include margin(true, padding-top);
    @include margin(true, padding-bottom);
    @include rtl-property(padding-left, math.div(56, 16) * 1rem);
    box-sizing: border-box;
  }

  &__meta {
    display: flex;
    justify-content: center;
  }
}
