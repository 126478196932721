@import "../../settings";
@import "../../lib/i18n";

.ProgressBar {
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  width: 100%;

  &__progressbar {
    appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    color: currentColor;
    display: block;
    height: $grid-margin-1;
    width: 100%;

    &::-moz-progress-bar {
      background-color: currentColor;
    }

    &::-webkit-progress-bar {
      background-color: transparent;
    }

    &::-webkit-progress-value {
      background-color: currentColor;
    }
  }

  &--background &__progressbar {
    background-color: $color-gray;
  }

  .Caption {
    @include rtl-property(margin-left, $grid-margin-4);
    box-sizing: border-box;
    line-height: 1;
  }
}
