@use "sass:math";

@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/typography";

.Form {
  box-sizing: border-box;
  width: 100%;

  &--horizontal {
    padding-top: $grid-margin-10 * 1;
  }

  &--alternate,
  &--big {
    @include container;
  }

  &--alternate {
    @include container--small;
  }

  &--big {
    @include container--big;
    width: 100%;
  }

  .SubTitle {
    @include margin($value-mobile: 2 * $grid-margin-5);
  }

  .FormControl {
    margin-top: $grid-margin-5;
  }

  &--vertical .FormControl {
    padding-bottom: $grid-margin-10 * 1;
  }

  &--pad > .Text:first-child,
  &--pad .FormControl,
  &--pad .FormActions {
    @include container-margin(
      $properties: (
        padding-left,
        padding-right,
      )
    );

    @include tablet {
      padding-left: 2 * $grid-margin-10;
      padding-right: 2 * $grid-margin-10;
    }
  }

  &--vertical .FormControl {
    @include border($color: $color-gray, $size: math.div(1, 16) * 1rem);
    align-items: flex-start;
    flex-direction: column;
  }

  &--vertical .FormControl .Label {
    @include margin;
    padding-right: 0;
  }

  &--vertical .FormControl .Input,
  &--vertical .FormControl .Select {
    width: 100%;
  }

  &--alternate .FormActions {
    justify-content: center;
  }
}
