@use "sass:math";

@import "../../settings";
@import "../../lib/i18n";
@import "~microscope-sass/lib/animation";
@import "~microscope-sass/lib/color";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/transform";
@import "~microscope-sass/lib/typography";
@import "../panel/constants";
@import "./constants";

.Col {
  align-items: center; // ???
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;

  &--collapsible {
    @include animate;
    @include rtl-property(right, 0);
    @include rtl-property(margin-left, 50%);
    overflow: visible;
    position: absolute;
  }

  &--collapsible#{&}--collapsed {
    @include tablet {
      @include translate(100%);

      @include rtl {
        @include translate(-100%);
      }
    }
  }
  &--collapsible#{&}--collapsed .Panel {
    @include mobile-only {
      display: none;
    }
  }

  &__collapse {
    @include absolute($bottom: 50%);
    @include border(all, $size: 1px, $color: $panel-border-color);
    @include rtl-property(border-right, none);
    @include rtl-property(right, 100%);
    @include rows(2);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    // Set min height for zoomed browsers (that don't have layout height set).
    @media (max-height: 567px) {
      bottom: calc(100% - 2 * #{$grid-row-height}) !important;
    }

    @include mobile-only() {
      @include color-primary(background-color);
      @include color-background(color);
      bottom: 32px;
    }
  }

  &__collapse .Icon {
    @include rtl {
      @include scale(-1);
    }
  }

  &--sidebar-left {
    @include breakpoint($max: $breakpoint-tablet - 1px) {
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;

      > * {
        flex-shrink: 1 !important;
      }

      .InfoButton {
        @include rows(0.2, top);
        @include rtl-property(left, 0.2 * $grid-row-height);
        position: absolute;
        z-index: 1000;

        &--active {
          z-index: 2000;
        }
      }

      .ComparativeEntityFilter {
      }
    }

    @include tablet {
      flex-shrink: 0;
      width: math.div(1, 16) * 200rem !important;
      z-index: 10;
    }
  }

  &--sidebar-left ~ & {
    @include mobile-only {
      height: calc(100% - #{math.div(86, 16) * 1rem});
    }
  }

  &--sidebar-right {
    z-index: 10;

    @include tablet {
      flex-shrink: 0;
      width: math.div(80, 16) * 1rem !important;
    }
  }

  &__collapse .Icon {
    @include animate;
  }

  &--collapsible#{&}--collapsed &__collapse .Icon {
    @include scale(-1);

    @include rtl {
      @include scale(1);
    }
  }

  &--overflow {
    overflow-y: visible;
  }

  &--no-overflow {
    overflow-y: hidden;
  }

  &--blur {
    position: relative;
    transform-style: preserve-3d;
  }

  &--blur::before {
    @include overlay;
    backdrop-filter: blur($col-blur-amount);
    content: "";
    position: fixed;
  }

  // Firefox doesn't support backdrop-filter.
  @supports not (backdrop-filter: blur($col-blur-amount)) {
    &--blur::before {
      @include overlay;
      background-image: inherit;
      content: "";
      filter: blur($col-blur-amount);
      position: fixed;
    }

    &--blur:not(#{&}--overflow) {
      overflow: hidden;
    }

    &--blur:not(#{&}--overflow):before {
      height: calc(100% + #{$col-blur-amount});
      width: calc(100% + #{$col-blur-amount});
    }
  }

  &--blur > * {
    position: relative;
    z-index: 10;
  }

  &--fill {
    @include color-background;
  }

  &--top {
    justify-content: flex-start;
  }

  &--bottom {
    justify-content: flex-end;
  }

  &--compensate-top {
    @include tablet {
      @media (min-height: math.div(1, 16)*700rem + 2 * $grid-row-height + 2 * $grid-container-margin) {
        @include rows(-2, margin-top);
        height: calc(100% + #{2 * $grid-row-height});
      }
    }
  }

  &--compensate-left {
    @include desktop {
      @include rtl-property(margin-left, math.div(-1, 16) * 100rem);
    }
  }

  &--side {
    align-items: flex-start;
  }

  &--center {
    @include tablet {
      justify-content: center;
    }
  }

  &--pad {
    @include container-margin($properties: padding);
  }

  &--pad-right {
    @include tablet {
      @include rtl-property(
        padding-right,
        math.div(1, 16) * 80rem + 2 * $grid-container-margin
      );
    }
  }

  @for $i from 1 through 12 {
    &[class*="--#{$i}"] {
      @include tablet {
        width: math.div(100, 12) * $i * 1%;
      }
    }
  }

  > * {
    flex-shrink: 0;
  }
}
