@import "../../settings";
@import "~microscope-sass/lib/grid";
@import "~microscope-sass/lib/responsive";
@import "~microscope-sass/lib/typography";
@import "~microscope-sass/lib/util";
@import "../../lib/i18n";

.RangeFilter {
  @include responsive(flex-direction, row, column);
  @include responsive(height, $grid-row-height, auto);
  display: flex;
  overflow: hidden;
  width: 100%;

  @include tablet {
    flex-shrink: 0;
    left: 0;
    position: sticky;
    z-index: 10;
  }

  &__head {
    position: relative;
  }

  &__head .Panel {
    @include container-margin(
      $properties: (
        padding-top,
        padding-bottom,
      )
    );
    @include hide-on-mobile;
  }

  .Panel {
    @include mobile-only {
      padding: 0;
      //background-color: transparent;
      //border-color: transparent;
    }
  }

  .Panel__body {
    @include responsive(flex-direction, row, column);

    @include mobile-only {
      @include rtl(
        mask,
        linear-gradient(
            to left,
            $color-background calc(100% - #{2 * $grid-row-height}),
            transparent calc(100% - #{$grid-row-height})
          )
          0 0/100% 100%,
        linear-gradient(
            to right,
            $color-background calc(100% - #{2 * $grid-row-height}),
            transparent calc(100% - #{$grid-row-height})
          )
          0 0 / 100% 100%
      );

      min-height: $grid-margin-8;
      justify-content: flex-end;
      width: 100%;
    }
  }

  &__head .Panel__body {
    min-height: 0;
  }

  &__head .SubCaption {
    margin-bottom: 0;
  }

  &__body {
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  .Panel {
    overflow-y: auto;
  }

  .Panel .Panel__body .Toggle {
    @include mobile-only {
      @include margin("auto", "margin-right");
      margin-bottom: 0;
      white-space: nowrap;
      width: auto;

      &:first-child {
        @include margin(true, "margin-left");
      }

      &:last-child {
        @include margin(true, "margin-right");
      }
    }
  }
}
